<template>
    <div class="rounded-3xl w-full">
      <video ref="videoPlayer" class="video-js vjs-default-skin" controls preload="auto" width="" height="">
        <source :src="props.videoUrl" type="application/x-mpegURL">
                      
      </video>
    </div>
  </template>
  
  <script setup>
  import { ref, onMounted, onUnmounted } from 'vue';
  import videojs from 'video.js';
  import 'video.js/dist/video-js.css';
  
  // import 'videojs-hls-quality-selector'; // Import the plugin



  const props = defineProps({
    videoUrl: String,
  });
  
  // Reference to the video element
  const videoPlayer = ref(null);
  let player = null;
  
  onMounted(() => {
    // Initialize Video.js player
    player = videojs(videoPlayer.value, {
      autoplay: false,
      controls: true,
      responsive: true,
      fluid: true,
      // plugins: {
      //   hlsQualitySelector: {
      //     displayCurrentQuality: true, // Show current quality in the selector
      //   },
      // },
    });
  
    player.ready(() => {
      console.log('Player is ready');
      // player.play();
    });
  });
  
  onUnmounted(() => {
    if (player) {
      player.dispose();
    }
  });
  </script>
  
  <style scoped>
  /* Add any additional styling here */
  </style>
  