<template>
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self="closeComponent">
      <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97%] max-w-[450px] overflow-auto">
        <!-- Cross button -->
        <div class="sticky top-6 mr-5 flex justify-end z-10">
          <button @click="closeComponent" class="border-black">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        
        <!-- Content container -->
        <div class="w-full flex flex-col px-4">
          <div class="font-semibold text-[22px] mt-2 mb-6 px-2 md:px-0">
            Edit Parent Details
          </div>
          
          <!-- Form -->
          <form @submit.prevent="updateParentDetails" class="mb-6 px-1">
            <!-- Name -->
            <div class="mb-7">
              <label for="parentName" class="block text-sm font-medium">Name</label>
              <input v-model="parentName" type="text" id="parentName" required class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
  
            <!-- Email (Read-only for display) -->
            <div class="mb-7">
              <label for="email" class="block text-sm font-medium">Email <span class="text-xs">(read only)</span> </label> 
              <input type="text" id="email" :value="props.parent.user_link" readonly class="mt-1.5 block w-full rounded-md border-gray-300 bg-gray-100 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
  
            <!-- Phone Number -->
            <div class="mb-7">
              <label for="phoneNumber" class="block text-sm font-medium">Phone Number</label>
              <input v-model="phoneNumber" type="text" id="phoneNumber" class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
            <div v-if="isNumberInvalid" class="text-red-500 text-sm mt-1">Please enter a valid country code (e.g., +91) followed by a 10-digit phone number.</div>
  

            <!-- Role -->
            <!-- Multi-Select Role Dropdown -->
              <div class="mb-7">
                <label for="roleSelect" class="block text-sm font-medium">Role</label>
                <div class="relative mt-1.5 cursor-pointer rounded">
                  <select  v-model="selectedRoles"  id="roleSelect"  ref="roleSelect" :key="roleSelectKey"  multiple data-hs-select='{
                  "placeholder": "Role",
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                  }' 
                    class="hidden">
                    <option v-for="role in availableRoles" :key="role" :value="role">
                      {{ role }}
                    </option>
                  </select>
                  <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path d="m7 15 5 5 5-5" />
                      <path d="m7 9 5-5 5 5" />
                    </svg>
                  </div>
                </div>
              </div>

            <!-- Children Field -->
            <!-- Children Multi-Select Dropdown -->
            <div class="mb-7">
              <label for="childrenSelect" class="block text-sm font-medium">Children</label>
              <div class="relative mt-1.5 cursor-pointer rounded">
                <select v-model="selectedChildren" id="childrenSelect" ref="childrenSelect" :key="childrenSelectKey" multiple data-hs-select='{
                  "hasSearch": true,
                  "placeholder": "Select Children",
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                  }' class="hidden">
                  <option v-for="child in availableChildren" :key="child.name" :value="child.name">
                    {{ child.student_name }}
                  </option>
                </select>
              </div>
            </div>


             <!-- Delete Parent Confirmation modal -->
          <div v-if="showDeleteConfirmationModal" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto px-3" @click.self="showDeleteConfirmationModal=false">
            <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97%] max-w-[450px] overflow-auto p-4">
               
              <div class="block text-lg"> Are you sure you want to delete the Parent ? </div>
             
              <div class="flex w-full justify-end mt-4 gap-x-4">
                <button type="button" @click="showDeleteConfirmationModal=false" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer bg-white text-black text-[14px] hover:bg-opacity-80" style="border: 1px solid #cbd5e1;">
                    <span>Cancel</span>
                </button> 
                
                <button type="button" :disabled="isDeleting" @click="deleteParent" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer bg-black text-white text-[14px] hover:bg-opacity-80" style="border: 1px solid #cbd5e1;">
                    <span v-if="isDeleting">Deleting...</span>
                    <span v-else>Delete</span>
                </button>
              </div> 
              <div v-if="isDeleting" class="text-green-500 text-sm mt-3">Deleting Parent...</div>
              <div v-if="errorMessage" class="text-red-500 text-sm mt-3">{{ errorMessage }}</div>
          
            </div>
          </div>

  
            <!-- Buttons -->
            <div class="flex justify-end mt-8 gap-x-3">

              <!-- Delete button -->
            <button type="button" :disabled="isDeleting" @click="showDeleteConfirmationModal=true">
                <svg width="29" height="29" viewBox="0 0 29 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M4.33984 7.87744H25.3398" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M23.0052 7.87744V24.2108C23.0052 25.3774 21.8385 26.5441 20.6719 26.5441H9.00521C7.83854 26.5441 6.67188 25.3774 6.67188 24.2108V7.87744" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M10.1719 7.8776V5.54427C10.1719 4.3776 11.3385 3.21094 12.5052 3.21094H17.1719C18.3385 3.21094 19.5052 4.3776 19.5052 5.54427V7.8776" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M12.5078 13.7109V20.7109" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                  <path d="M17.1719 13.7109V20.7109" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
            </button>

              <button type="button" @click="resetChanges" class="bg-white text-blue-600 py-2.5 px-1 w-[135px] text-[14px] text-start rounded-lg hover:text-gray-500 cursor-pointer">Reset changes</button>
              <button type="submit" :disabled="isLoading || isNumberInvalid ||!props.parent.user_link" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer bg-black text-white text-[14px] hover:bg-opacity-80" style="border: 1px solid #cbd5e1;"
              :class="{
                'bg-gray-300 text-gray-500': isLoading || isNumberInvalid || !props.parent.user_link,
              }">
                <span v-if="isLoading">Saving...</span>
                <span v-else>Save</span>
              </button>
            </div>

            <div v-if="isLoading" class="text-green-500 text-sm mt-4">Updating Parent details</div>
            <div v-if="errorMessage" class="text-red-500 text-sm mt-4">{{ errorMessage }}</div>


          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, inject, defineEmits, defineProps, computed, onMounted, nextTick, watch } from 'vue';
  
  const props = defineProps({
    parent: {
      type: Object,
      required: true
    },
    optionsResponse: Object,
  });
  
  const emit = defineEmits(['close', 'update-success']);
  const $call = inject('$call');

  const isLoading = ref(false);
  const errorMessage = ref(null);  // To store error messages


  const isDeleting = ref(false);
  const showDeleteConfirmationModal = ref(false)

  
  // Initialize form fields with props data
  const parentName = ref(props.parent.parent_name);
  const phoneNumber = ref(props.parent.mobile_no || '+91');
  const children = ref(props.parent.children || '');
  
  
  const availableRoles = ref([]);
  const selectedRoles = ref([]);
  
  const roleSelectKey = ref(0);
  const childrenSelectKey = ref(0);


  const selectedChildren = ref([]);
  const availableChildren = ref(props.optionsResponse.students || []); // `allChildren` is the list received as a prop
  
  const preSelectedRole = ref([]);
  const preSelectedChildren = ref([]);

  const isNumberInvalid = ref(false); 


// Fetch all roles and initialize Preline select on mount
onMounted(async () => {
  // Fetch roles from the API
  try {
    const response = await $call('curriculum_planner.curriculum_planner.common.educator.api.get_list_of_all_the_roles');
    availableRoles.value = response.roles || []; // Assuming roles come in a 'roles' field

    // Preselect roles if provided in props
    preSelectedRole.value =props.parent.roles || []; //To store the value and not mutate it in future
    selectedRoles.value = props.parent.roles || [];
    preSelectedChildren.value = props.parent.children.map(child => child.student_id) || [];
    selectedChildren.value = preSelectedChildren.value

    console.log("tt:this is selected children value",selectedChildren.value)
    console.log("tt: parent props",props.parent)

    setTimeout(() => {
      window.HSStaticMethods.autoInit(); // Initialize custom selects

      // For role select dropdown
      const roleSelectElement = window.HSSelect.getInstance('#roleSelect');
      if (roleSelectElement) {
        roleSelectElement.on('change', (value) => {
          selectedRoles.value = value ? [...value] : [];
        });
        console.log("selected value changed for Roles",selectedRoles.value)
      } else {
        console.error('RoleSelect instance not found');
      }

      // For Children dropdown
      const childrenSelectElement = window.HSSelect.getInstance('#childrenSelect');
      if (childrenSelectElement) {
        childrenSelectElement.on('change', (value) => {
          selectedChildren.value = value ? [...value] : [];
        });
        console.log("selected value changed for Children",selectedChildren.value)
      }else {
        console.error('childrenSelect instance not found');
      }


    }, 100);
  } catch (error) {
    console.error('Error fetching roles:', error);
  }
});


// Custom debounce function
function debounce(func, delay) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
       timeout = setTimeout(() => {
        func(...args);
       }, delay);
    };
  }


// Debounced phone number validation function
const validatePhoneNumber = debounce(() => {
    const phonePattern = /^\+[0-9]{1,3}[0-9]{10}$/;
    isNumberInvalid.value = phoneNumber.value
      ? !phonePattern.test(phoneNumber.value)
      : false; // True if invalid, false if valid or empty
  }, 1200); 

  // Watch the phoneNumber field and validate with debounce
  watch(phoneNumber, () => {
    validatePhoneNumber();
  });


  
  
  const updateParentDetails = async () => {
    isLoading.value = true;
    errorMessage.value = null;
    try {
      const response = await $call('curriculum_planner.curriculum_planner.common.parents.api.update_parent_details', {
        parent_id: props.parent.parent_id,
        new_name: parentName.value,
        new_roles: selectedRoles.value,
        phone_number: phoneNumber.value,
        children: selectedChildren.value
      });
  
      if (response.status === 'success') {
        emit('update-success');
        emit('close');
      }
       else {
        errorMessage.value = response.message || 'An error occurred while saving the Parent details.';
      }
    } 
    catch (error) {
      if (error.message.includes('Duplicate entry')) {
        errorMessage.value = 'A user with this email already exists. Please use a different email.';
      } else {
        errorMessage.value = 'An error occurred while saving the Parent details.';
      }
      console.error('Error saving parent:', error);
    }
   finally {
    isLoading.value = false;
   }
  };



  // API call to update educator details
  const deleteParent = async () => {
    isDeleting.value = true;
    errorMessage.value = null;
    try {
      const response = await $call('curriculum_planner.curriculum_planner.common.parents.api.archive_parent', {
        parent_id: props.parent.parent_id,
      })

      if (response.status === 'success') {
        showDeleteConfirmationModal.value= false
        emit('close');
        emit('update-success')
      } else {
          errorMessage.value = response.message || 'An error occurred while deleting Parent.';
        }
      } catch (error) {
        if (error.message.includes('Duplicate entry')) {
          errorMessage.value = 'A user with this email already exists. Please use a different email.';
        } else {
          errorMessage.value = 'An error occurred while deleting Parent.';
        }
        console.error('Error deleting the Parent', error);
      }
    finally {
      isDeleting.value = false;
    }
  };
  

  const reinitializePrelineSelect = async () => {
    console.log("Reinitializing Preline selects...");

    // Reinitialize Role select
    const roleSelect = window.HSSelect.getInstance(`#roleSelect`);
    if (roleSelect) {
      roleSelect.destroy();
    }
    roleSelectKey.value++;

    // Reinitialize Children select
    const childrenSelect = window.HSSelect.getInstance(`#childrenSelect`);
    if (childrenSelect) {
      childrenSelect.destroy();
    }
    childrenSelectKey.value++;

    await nextTick();
    window.HSStaticMethods.autoInit();
  };

  // Reset the form to original values
const resetChanges = () => {
  parentName.value = props.parent.parent_name;
  phoneNumber.value = props.parent.mobile_no || '+91';
  selectedRoles.value = preSelectedRole.value;
  selectedChildren.value = preSelectedChildren.value;
  isNumberInvalid.value = false;
  reinitializePrelineSelect();
};
  
  // Close component
  const closeComponent = () => {
    emit('close');
  };
  </script>
  
  <style scoped>
  /* Styling for the modal */
  .bg-conic-gradient {
    background: conic-gradient(from 90deg at 50% 50%, #FFCE31 0deg, rgba(255, 206, 49, 0.2) 294deg, rgba(255, 206, 49, 0) 360deg);
  }
  
  .spinner-mask {
    --spinner-thickness: 3px;
    mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
  }
  </style>
  