<template>
  <div class="h-full">
      <!-- Tool bar -->
      <div class="flex flex-col md:flex-row md:mr-0 md:mb-6 justify-center md:justify-start px-4 md:px-0">
          
          
          <!-- Search -->
          <div class="flex flex-row items-center mb-3 md:mb-0">
          <input class="w-full border border-slate-300 rounded-full px-4 py-3 text-xs md:text-sm align-middle" type="text" placeholder="Search"
          v-model="searchQuery" >
          </div> 

          <!-- Filters and add Photos/videos button container -->
          <div class="flex flex-wrap items-start w-full md:w-[100%] z-20 w-[80%] gap-x-8 md:ml-4">

              <!-- Cohort Filter-->
              <div v-if="optionsResponse && optionsResponse.cohorts" class="border w-[45%] md:w-[20%] rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">
                  <div class="relative">
                      <select id="cohortsSelect" ref="cohortsSelect" multiple data-hs-select='{
                      "placeholder": "Cohorts",
                      "toggleTag": "<button type=\"button\"></button>",
                      "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                      "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                      "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                      "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                      }' class="hidden">
                          <option v-for="cohort in optionsResponse.cohorts" :key="cohort.name" :value="cohort.name">
                          {{ cohort.cohort_name}}
                          </option>
                      </select>

                      <div class="absolute top-1/2 end-3 -translate-y-1/2">
                          <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                          width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                          stroke-linecap="round" stroke-linejoin="round">
                          <path d="m7 15 5 5 5-5" />
                          <path d="m7 9 5-5 5 5" />
                          </svg>
                      </div>
                      <!-- End Select -->
                  </div>
              </div>

              <!-- Sort by Filter-->
              
          
          </div>
          
      </div>

      <!-- Learners -->
      <div v-if="learnersListData.length>0 && filteredLearnersList.length > 0" class="overflow-auto max-h-[80%] md:max-h-[83%] w-full flex flex-col md:flex-row md:flex-wrap md:gap-x-6 mt-4 px-4 md:px-0">
          
         <div v-for="(learner, index) in filteredLearnersList" :key="index">
            <div class="flex gap-2 border border-slate-200 pl-4 rounded-lg pt-3 pb-3 mb-4 md:min-w-[280px] md:max-w-[320px] pr-2">
            <!-- <router-link :to="{ name: 'LearnerDetails', params: { learnerId: learner.student_id }, query: { page: currentPage } }" > -->
                
                <!-- pfp -->
                <img :src=learner.presigned_s3_url @click="selectLearnerForViewDetails(learner.student_id)" class="rounded-full w-16 h-16 cursor-pointer" alt="Profile Picture">
                
                <!-- Info -->
                <div class="ml-2 w-[80%]">
                        <!-- Name -->
                        <span class="font-medium mr-2 text-sm">{{ learner.student_name }}</span>

                        <!-- Age -->
                        <div>
                            <span class="font-medium mr-2 text-sm">Age:</span>
                            <span class="text-slate-500 text-sm">{{ calculateAge(learner.date_of_birth)}}</span>
                        </div>

                        <!-- Cohort -->
                        <div>
                            <span class="font-medium mr-2 text-sm">Cohort:</span>
                            <span class="text-slate-500 text-sm">{{ learner.cohort_name }}</span>
                        </div>
                </div>

                <!-- Edit student details Icon -->
                <div class="flex self-center cursor-pointer" @click="selectLearnerForEdit(learner.student_id)">
                    <svg width="19" height="19" viewBox="0 0 19 19" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M13.3242 2.30541C13.5152 2.07968 13.7515 1.89595 14.018 1.76592C14.2845 1.63589 14.5753 1.56241 14.872 1.55017C15.1686 1.53793 15.4646 1.58719 15.741 1.69482C16.0174 1.80245 16.2682 1.96609 16.4774 2.1753C16.6867 2.38451 16.8497 2.63471 16.9561 2.90994C17.0626 3.18517 17.1101 3.47941 17.0957 3.77389C17.0813 4.06838 17.0052 4.35665 16.8724 4.62033C16.7396 4.88402 16.5529 5.11733 16.3242 5.30541L6.19922 15.4304L2.07422 16.5554L3.19922 12.4304L13.3242 2.30541Z" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                        <path d="M11.8242 3.80518L14.8242 6.80518" stroke="black" stroke-linecap="round" stroke-linejoin="round"/>
                    </svg>
                </div>
                

            <!-- </router-link> -->
            </div>
          </div>


             <!-- Pagination Controls -->
            <div v-if="learnersListData.length>0 && filteredLearnersList.length > 0" class="justify-end gap-x-3 flex items-center w-full px-3 mt-2">
                <button @click="goToPreviousPage" :disabled="currentPage === 1" class="text-xl font-semibold">&larr;</button>
                <span>Page {{ currentPage }} of {{ totalPages }}</span>
                <button @click="goToNextPage" :disabled="currentPage === totalPages" class="text-xl font-semibold">&rarr;</button>
            </div>


      </div>

      <!-- Spinner logo -->
      <div v-if="isLoading" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 flex items-center justify-center z-50">
          <div class="relative inline-block w-16 h-16" role="status" aria-label="loading">
              <div class="absolute inset-0 bg-conic-gradient rounded-full animate-spin spinner-mask" style="--spinner-thickness: 8px;"></div>
              <span class="sr-only">Loading...</span>
          </div>
      </div>


      <EditLearnerForm v-if="selectedStudentId" :student-id="selectedStudentId" :options-response="props.optionsResponse" @close="selectedStudentId = null" @submit-success="handleSubmitSuccess"></EditLearnerForm>

      <viewLearnerDetailsModal v-if="selectedStudentIdForViewDetails" :student-id="selectedStudentIdForViewDetails" @close="selectedStudentIdForViewDetails = null" @pfp-update-success="handlePfpUploadSuccess"></viewLearnerDetailsModal>

  </div>    


</template>



<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import moment from 'moment';
import { useRoute } from 'vue-router';
import EditLearnerForm from './EditLearnerForm.vue';
import viewLearnerDetailsModal from './viewLearnerDetailsModal.vue';


const route = useRoute();


// Inject the $call method
const $call = inject('$call');

const learnersListData = ref([]);
const selectedCohorts = ref([]);
const searchQuery = ref(""); 

const currentPage = ref(1); // Current page
const pageSize = ref(20); // Students per page
const totalPages = ref(0); // Total pages from API
const isLoading = ref(true);

const selectedStudentId = ref(null);
const selectedStudentIdForViewDetails = ref(null);


const props = defineProps({
optionsResponse: Object,
reRenderCounter: {
    type: Number,
    default: 0
  }
});


onMounted(() => {
  currentPage.value = parseInt(route.query.page) || 1;
  fetchLearnersProfileList()
});

watch(
  () => props.optionsResponse,
  (newVal, oldVal) => {
      if (newVal) {
      setTimeout(() => {
          window.HSStaticMethods.autoInit();
          console.log("Preline initialised in worksheets.vue");

          // Initialize the select element for cohorts
          const cohortsSelectElement = window.HSSelect.getInstance('#cohortsSelect');
          if (cohortsSelectElement) {
          cohortsSelectElement.on('change', (value) => {
              console.log('Select value changed:', value);
              selectedCohorts.value = value
              // fetchWorksheets();
              fetchLearnersProfileList();
          });
          } else {
          console.log('AdvancedSelect cohorts instance not found');
          }},100)
      }
  },
  { immediate: true } // This ensures the watcher is triggered immediately if optionsResponse is already set
); 




const selectLearnerForEdit = (studentId) => {
  selectedStudentId.value = studentId;
};

const selectLearnerForViewDetails = (studentId) =>{
    selectedStudentIdForViewDetails.value = studentId;
};

const handleSubmitSuccess = ()=>{
    fetchLearnersProfileList();
}
const handlePfpUploadSuccess = () => {
    fetchLearnersProfileList();
}


  // Computed property to filter learners based on searchQuery
  const filteredLearnersList = computed(() => {
      if (!searchQuery.value) {
          return learnersListData.value;
      }
      return learnersListData.value.filter(learner =>
          learner.student_name.toLowerCase().includes(searchQuery.value.toLowerCase())
      );
  });

  const fetchLearnersProfileList = async () => {
      try {
          const response = await $call('curriculum_planner.curriculum_planner.common.learners.api.get_all_students_list', {
              cohort_id: selectedCohorts.value,
              page_number: currentPage.value,
              page_size: pageSize.value
          });
          learnersListData.value = response.students;
          totalPages.value = response.pagination.total_pages; // Set total pages
          isLoading.value=false;
          console.log("Learners list fetched",response)
      } catch (error) {
          console.error('Error fetching Learners list:', error);
      }
  };

  // Watch for changes in reRenderCounter prop
  watch(() => props.reRenderCounter, (newValue) => {
    fetchLearnersProfileList();
    });

  const calculateAge = (dateOfBirth) => {
      if (!dateOfBirth) {
          return 'NA';
      }

      const dob = new Date(dateOfBirth);
      if (isNaN(dob.getTime())) {
          return 'NA';
      }

      const now = new Date();
      const yearsDiff = now.getFullYear() - dob.getFullYear();
      const monthsDiff = now.getMonth() - dob.getMonth();

      let years = yearsDiff;
      let months = monthsDiff;

      if (monthsDiff < 0 || (monthsDiff === 0 && now.getDate() < dob.getDate())) {
          years--;
          months = 12 + monthsDiff;
      }

      if (years === 0) {
          return `${months} month${months !== 1 ? 's' : ''}`;
      } else if (months === 0) {
          return `${years} year${years !== 1 ? 's' : ''}`;
      } else {
          return `${years} year${years !== 1 ? 's' : ''} ${months} month${months !== 1 ? 's' : ''}`;
      }
  };
  

  
  // Methods to navigate between pages
  const goToNextPage = () => {
      if (currentPage.value < totalPages.value) {
          currentPage.value++;
          fetchLearnersProfileList();
      }
  };

  const goToPreviousPage = () => {
      if (currentPage.value > 1) {
          currentPage.value--;
          fetchLearnersProfileList();
      }
  };

</script>
  
<style scoped>

/* Styling for loading spinner */
.bg-conic-gradient {
  background: conic-gradient(from 90deg at 50% 50%, #FFCE31 0deg, rgba(255, 206, 49, 0.2) 294deg, rgba(255, 206, 49, 0) 360deg);
 }

.spinner-mask {
  --spinner-thickness: 3px;
  mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
  -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
}


</style>