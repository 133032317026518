<template>
  <div class="flex flex-col gap-4">
    <div class="flex flex-col gap-2">
      <h4 class="font-semibold text-xl">Assessment Report: </h4>
      <div v-if="props.assessmentReport?.formatted_report" 
        v-html="props.assessmentReport.formatted_report">
      </div>
    </div>

    <div class="flex flex-col gap-2">
      <h4 class="font-semibold text-xl">Individual Observations: </h4>
      <div v-for="observation, index in props.dailyObservList">
        <span class="font-semibold">{{ index + 1 }}. </span>
        <!-- {{ observation }} -->
        <span v-for="(value, key) in qMap" :key="key">
          <br />
          <span>{{ value }}</span> :
          <span v-if="key !== 'anecdote_competencies' && key != 'educator_name'"
            class="font-semibold">
            {{ observation[key] }},
          </span>
          <span v-if="key === 'educator_name' && userDetails?.school_info" class="font-semibold">
            {{ findValueByName(userDetails.school_info.educators, observation[key], 'educator_name') }},
          </span>
          <span v-if="key === 'anecdote_competencies'"
            v-for="competency in observation[key]"
            class="font-semibold">
            {{ competency.competency_name }},
          </span>
        </span>
      </div>
    </div>

    <div class="flex flex-col gap-2">
      <h4 class="font-semibold text-xl">Activity Observations: </h4>
      <div v-for="observation, index in props.activityObservList">
        <span class="font-semibold">{{ index + 1 }}. </span>
        <!-- {{ observation }} -->
        <span v-for="(value, key) in activityQMap" :key="key">
          <br />
          <span>{{ value }}</span> :
          <span class="font-semibold">
            {{ observation[key] }},
          </span>
          <!-- <span v-if="key === 'secondary_competencies'"
            v-for="competency in observation[key]"
            class="font-semibold">
            {{ competency }},
          </span> -->
        </span>
      </div>
    </div>

    <!-- <div class="flex flex-col gap-2">
      <h4 class="font-semibold text-xl">Student Mentions in Activity Observations: </h4>
      <div v-for="mention, index in props.activityMentionList">
        <span class="font-semibold">{{ index + 1 }}. </span>
        <span v-for="(value, key) in mentionQMap" :key="key">
          <br />
          <span>{{ value }}</span> :
          <span class="font-semibold" v-if="key !== 'educator'">
            {{ mention[key] }},
          </span>
          <span v-if="key === 'educator' && userDetails?.school_info" class="font-semibold">
            {{ findValueByName(userDetails.school_info.educators, mention[key], 'educator_name') }},
          </span>
          <span v-if="key === 'secondary_competencies'"
            v-for="competency in observation[key]"
            class="font-semibold">
            {{ competency }},
          </span>
        </span>
      </div>
    </div> -->
  </div>
</template>

<script setup>
import { inject } from 'vue';
import { findValueByName } from '../../../../lib/utils';

const props = defineProps([
  'dailyObservList', 'activityObservList', 'activityMentionList',
  'assessmentReport'
])

const userDetails = inject('userDetails')

const qMap = {
  'date': 'Date',
  'educator_name': 'Educator',
  // 'learners': 'Child',
  'anecdotes': 'Anecdotes from the day',
  'anecdote_competencies': 'Competencies anecdote is linked to',
  'custom_competency_text': 'Competency text'
}

const activityQMap = {
  'date': 'Date',
  'activity_name': 'Activity',
  'activity_summary': 'Summary',
  'student_mention': 'Mention of Student',
  'primary_competency': 'Primary Competency',
  'secondary_competencies': 'Secondary Competencies',
  'engagement_level': 'Engagement'
}

const mentionQMap = {
  'date': 'Date',
  'educator': 'Educator',
  'observation_summary': 'Observation Summary',
  'extract': 'Mention',
}

console.log(props.dailyObservList)
console.log(props.activityObservList)
</script>