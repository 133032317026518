<template>
    <!-- <testObservationPhotoUpload></testObservationPhotoUpload> -->

    <!-- worksheet body -->
    <div class="h-[100%] overflow-auto">

            <div class="text-3xl mt-8 mb-4 ml-6 md:ml-10"> Worksheets</div>

            <!-- Filters container -->
            <div class="grid grid-cols-3 md:grid-cols-4 lg:grid-cols-6 items-start w-[90%] lg:w-[90%] mb-6 ml-6 md:ml-10 gap-2 md:gap-4 lg:gap-6">

                <!-- Cohort Filter-->
                <div v-if="optionsResponse && optionsResponse.cohorts && userDetails && (userDetails.user_roles.includes ('System Manager') || userDetails.user_roles.includes('Educator'))" class="border rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">

                    <div class="relative">

                    <select id="cohortsSelect" ref="cohortsSelect" multiple data-hs-select='{
                    "placeholder": "Cohorts",
                    "toggleTag": "<button type=\"button\"></button>",
                    "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                    "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                    "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                    "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                    }' class="hidden">
                        <option v-for="cohort in optionsResponse.cohorts" :key="cohort.name" :value="cohort.name">
                        {{ cohort.cohort_name}}
                        </option>
                    </select>

                    <div class="absolute top-1/2 end-3 -translate-y-1/2">
                        <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                        width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                        stroke-linecap="round" stroke-linejoin="round">
                        <path d="m7 15 5 5 5-5" />
                        <path d="m7 9 5-5 5 5" />
                        </svg>
                    </div>
                    </div>
                    <!-- End Select -->
                </div>

                <!-- Competency Filter -->
                <div v-if="optionsResponse && optionsResponse.competencies" class="border rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">
                    <div class="relative">

                    <select id="competenciesSelect" ref="competenciesSelect" multiple data-hs-select='{
                    "placeholder": "Competency",
                    "toggleTag": "<button type=\"button\"></button>",
                    "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-wrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                    "dropdownClasses": "mt-2 z-50 w-full text-wrap max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                    "optionClasses": "py-2 px-1 md:px-4 text-wrap w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                    "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                    }' class="hidden">
                        <option v-for="competency in optionsResponse.competencies" :key="competency.name" :value="competency.name">{{
                        competency.competency_name }}
                        </option>
                        </select>
                        <div class="absolute top-1/2 end-3 -translate-y-1/2">
                            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                            width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                            stroke-linecap="round" stroke-linejoin="round">
                            <path d="m7 15 5 5 5-5" />
                            <path d="m7 9 5-5 5 5" />
                            </svg>
                        </div>
                    </div>
                        <!-- End Select -->
                </div>

                <!-- Month Filter -->
                <div class="border rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">
                    
                        <div class="relative">
                            <select v-model="selectedMonth" id="monthsSelect" ref="monthsSelect" multiple data-hs-select='{
                                "placeholder": "Month",
                                "toggleTag": "<button type=\"button\"></button>",
                                "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                                "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                                "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                                "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                                }' class="hidden">
                                    <option value="">Select a Month</option>
                                    <option v-for="(month, index) in months" :key="index" :value="month">{{ month }}</option>
                            </select>

                            <div class="absolute top-1/2 end-3 -translate-y-1/2">
                                <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                                    width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round">
                                    <path d="m7 15 5 5 5-5" />
                                    <path d="m7 9 5-5 5 5" />
                                </svg>
                            </div>
                        </div>
                        <!-- End Select -->
                </div>

                <!-- Year Filter -->
                <div class="border rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">
                    
                    <div class="relative">
                        <select v-model="selectedYears" id="yearsSelect" ref="yearsSelect" multiple data-hs-select='{
                            "placeholder": "Year",
                            "toggleTag": "<button type=\"button\"></button>",
                            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                            "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                            }' class="hidden">
                            <option value="">Select a Year</option>
                            <option v-for="(year, index) in years" :key="index" :value="year">{{ year }}</option>
                        </select>

                        <div class="absolute top-1/2 end-3 -translate-y-1/2">
                            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5" />
                                <path d="m7 9 5-5 5 5" />
                            </svg>
                        </div>
                    </div>
                    <!-- End Select -->

                </div>

                 <!-- Sorting Filter -->
                 <div class="border rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">
                    
                    <div class="relative">
                        <select v-model="selectedSort" id="sortingSelect" ref="sortingSelect" data-hs-select='{
                            "placeholder": "Sort",
                            "toggleTag": "<button type=\"button\"></button>",
                            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                            "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                            }' class="hidden">
                            <option value="desc">Sort: New to old</option>
                            <option value="asc">Sort: Old to New</option>
                        </select>

                        <div class="absolute top-1/2 end-3 -translate-y-1/2">
                            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5" />
                                <path d="m7 9 5-5 5 5" />
                            </svg>
                        </div>
                    </div>
                    <!-- End Select -->
                </div>

                 <!-- Status Filter -->
                <div v-if="userDetails && userDetails.user_roles.includes ('Parent') && !userDetails.user_roles.includes ('System Manager')  " class="border rounded-[8px]" style="border-color: rgba(212, 215, 221, 1);">
                    
                    <div class="relative">
                        <select v-model="selectedStatus" id="statusSelect" ref="statusSelect" multiple data-hs-select='{
                            "placeholder": "Status",
                            "toggleTag": "<button type=\"button\"></button>",
                            "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                            "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                            "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                            "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http://www.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                            }' class="hidden">
                            <option :value="0">{{ "Not Done" }}</option>
                            <option :value="1">{{ "Done" }}</option>
                            <!-- <option :value= null >{{ "All" }}</option> -->
                        </select>

                        <div class="absolute top-1/2 end-3 -translate-y-1/2">
                            <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                                width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                stroke-linecap="round" stroke-linejoin="round">
                                <path d="m7 15 5 5 5-5" />
                                <path d="m7 9 5-5 5 5" />
                            </svg>
                        </div>
                    </div>
                    <!-- End Select -->

                </div>

                <!-- Add worksheets button -->
                <button v-if="props.userRoleView && ( props.userRoleView== 'System Manager' || props.userRoleView =='Educator' )" class=" self-center text-white truncate bg-black rounded text-center text-sm py-3 px-3 ml-1" @click="showAddWorksheetModal=true">Add worksheets</button>
       
            </div>

            <!-- <div v-if="worksheets.length<=0" class="ml-6 md:ml-10">No worksheet has been assigned to the learner</div> -->
            
            <!-- Worksheet card container -->
            <div v-if="worksheets" class="grid grid-cols-2 md:flex md:flex-wrap ml-6 mr-2 md:mr-0 gap-2 md:gap-0 md:ml-10">  

                <!-- Worksheet card -->
                <div v-for="worksheet in worksheets" :key="worksheet.name" class="relative w-[95%] md:w-[220px] py-5 border border-slate-300 flex justify-center rounded-md mr-8 mb-6">

                    <span v-if="isLessThanWeekOld(worksheet.datetime_of_assignment) && worksheet.is_completed == 0" class="absolute top-2 right-2 mr-1 mb-2 inline-block rounded-3xl px-4 py-1.5 text-xs font-medium text-black"
                        style="background-color: #CCFBF1;">
                        New
                    </span>
                    <span v-if="worksheet.is_completed == 1" class="absolute top-2 right-2 mr-1 mb-2 inline-block rounded-3xl px-4 py-1.5 text-xs font-medium text-black"
                            style="background-color: #D0FBCC;">
                            Done
                    </span>
                    <span v-if="!isLessThanWeekOld(worksheet.datetime_of_assignment) && worksheet.is_completed == 0" class="absolute top-2 right-2 mr-1 mb-2 inline-block rounded-3xl px-3.5 py-1.5 text-xs font-medium text-black"
                            style="background-color: #FBE3CC;;">
                            Not Done
                    </span>
                    <!-- Worksheet card content container -->
                    <div class="w-auto md:w-[200px] flex flex-col px-2">

                        <div class="h-[90%]">
                            <!-- Preview Image -->
                            <div v-if="worksheet.preview_image_url" class="w-auto flex justify-center mb-2">
                                <img :src="worksheet.preview_image_url" class="w-[160px] h-[140px]"/>
                                
                            </div>
                            <div v-else class="w-auto flex justify-center mb-2">
                                <img src="../assets/worksheet-icons/placeholder-worksheet-icon.png" class="w-[136.8px] h-[120px]"/>
                                
                            </div>

                            <!--Worksheet Title -->
                            <div class="font-medium text-wrap w-full truncate">{{ worksheet.title}}</div>

                            <!-- Cohort -->
                            <div v-if="worksheet" class="flex flex-wrap">
                                <div v-for="(cohort, cohortIndex) in worksheet.for_cohort" :key="`tag-${cohortIndex}`">
                                    <span class="text-sm text-slate-500 font-medium mr-2">
                                    {{ cohort.cohort_name }}
                                    </span>
                                </div>
                            </div>

                            <!-- Student name -->
                            <div v-if="worksheet" class="flex flex-wrap">
                                <span class="text-sm text-slate-500 font-medium mr-2">
                                {{ worksheet.student_name }}
                                </span>
                            </div>

                            <!-- Competencies -->
                            <div v-if="worksheet.competencies" class="tags-container flex mt-2 flex-wrap max-w-[200px] truncate">
                                <div v-for="(competency, tagIndex) in worksheet.competencies" :key="`tag-${tagIndex}`">
                                    <span class="tag-badge mr-1 mb-2 inline-block truncate max-w-[150px] rounded-md px-1.5 py-1 text-xs font-medium text-black"
                                    style="background-color: #FDD835;">
                                    {{ competency }}
                                    </span>
                                </div>
                            </div>

                        </div>    

                            <!-- view details button -->
                            <router-link :user-role-view="props.userRoleView" :to="{ name: 'WorksheetDetails', params: { worksheetName: worksheet.worksheet_name } }">
                                <div class="text-white bg-black rounded text-center text-xs py-2 px-3"> View details
                                </div>
                            </router-link>
                        </div>    

                </div>

            </div> 

            <!-- Pagination Controls -->
            <div v-if="worksheets.length>0" class="pagination-controls flex justify-center mt-4">
                <button @click="prevPage" :disabled="pageNumber === 1" class="px-4 py-2 border rounded mr-2">
                    Previous
                </button>
                <span v-if="totalCount" class="px-4 py-2">{{ pageNumber+'/'+ Math.ceil(totalCount/4) }}</span> <!-- Divided by 4 becuase of pagination, that is only 4 docs are being fetched per page-->
                <button @click="nextPage" :disabled="!hasMoreData" class="px-4 py-2 border rounded ml-2">
                    Next
                </button>
            </div>
        
      <template v-if="showAddWorksheetModal">
        <AddWorksheetModal :optionsResponse="optionsResponse" @close="showAddWorksheetModal = false" @worksheet-created="handleWorksheetCreated"
        @worksheet-error="handleWorksheetError"></AddWorksheetModal>    
      </template>

     
        
    </div>    

</template>

<script setup>
import { ref, onMounted, inject, computed, watch } from 'vue';
import moment from 'moment';
import AddWorksheetModal from '../components/worksheets/AddWorksheetModal.vue';
import WorksheetDetails from '../views/WorksheetDetails.vue'

// import testObservationPhotoUpload from '../components/videoUpload/testObservationPhotoUpload.vue';

const props = defineProps({
  optionsResponse: Object,
  userDetails: Object,
  userRoleView: {
      type: String,
      required: true,
    }
});



// Inject the $call method
const $call = inject('$call');

const worksheets = ref([]);

// pagination variables
const pageNumber = ref(1);
const pageSize = ref(4);
const hasMoreData = ref(true);
const totalCount = ref(0)

const selectedCohorts = ref([]);
const selectedCompetencies = ref([]);
const selectedMonths = ref([]);
const selectedYears = ref(['2024']);
const dateFilters = ref([]);
const selectedStatus = ref(null)
const selectedSort = ref('desc')

const selectedWorksheetName = ref();
const showAddWorksheetModal = ref(false)



onMounted(() => {
    checkUserRoleAndFetchWorksheets();
   
    })



const months = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
const years = Array.from({ length: 5 }, (v, i) => moment().year() - i);



const handleWorksheetCreated = (message) => {
    // Handle successful worksheet creation
    console.log("worksheet created success recieved in parent######################")
   alert('Worksheet created:', message);
    // Additional logic...
};

const handleWorksheetError = ({ error, details }) => {
    // Handle worksheet creation error
    alert('Error creating worksheet:', error, details);
    // Additional error handling...
};


// checking user role in front end because backend filter is too complicated with all the filter logic
const checkUserRoleAndFetchWorksheets = async () => {
    
    if (props.userRoleView) {
        // check prop role then verify userdetails
        if ( props.userRoleView== 'System Manager' || props.userRoleView =='Educator' )
        {
           // Call fetchWorksheets for System Manager or Educator
            await fetchWorksheets();  
          } 
        // check prop role then verify user details  
        else if ( props.userRoleView== 'Parent') 
        {
        // Call fetchWorksheetsForParents for Parent
        await fetchWorksheetsForParents();
        }
    }
  };


const fetchWorksheets = async () => {
    try {
        console.log("fetch worksheets called and this is dateFilters.value",dateFilters.value)
        const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet.api.get_worksheet_metadata', {
        page_number: pageNumber.value,
        page_size: pageSize.value,
        cohort_ids: selectedCohorts.value,
        competency_ids: selectedCompetencies.value,
        date_filters: dateFilters.value,
        sort_order: selectedSort.value
        });

        totalCount.value = response.total_count;
        worksheets.value = response.worksheets;
        hasMoreData.value = response.worksheets.length === pageSize.value;
        console.log("worksheet data fetched in worksheets font-portal", response);
    } catch (error) {
        console.error('Error fetching the worksheets data:', error);
    }
    };


const fetchWorksheetsForParents = async () => {
    try {
        console.log("fetch worksheets called and this is dateFilters.value",dateFilters.value)
        const response = await $call('curriculum_planner.curriculum_planner.doctype.worksheet.api.get_worksheet_metadata_for_parents', {
        page_number: pageNumber.value,
        page_size: pageSize.value,     
        competency_ids: selectedCompetencies.value,
        status: selectedStatus.value,
        date_filters: dateFilters.value,
        sort_order: selectedSort.value
        });
        totalCount.value = response.total_count;
        worksheets.value = response.worksheets;
        hasMoreData.value = response.worksheets.length === pageSize.value;
        console.log("worksheet data fetched in worksheets font-portal for Parent", response);
    } catch (error) {
        console.error('Error fetching the worksheets data:', error);
    }
    };

    const nextPage = () => {
    pageNumber.value += 1;
    // fetchWorksheets();
    checkUserRoleAndFetchWorksheets();
    };

    const prevPage = () => {
    if (pageNumber.value > 1) {
        pageNumber.value -= 1;
        // fetchWorksheets();
        checkUserRoleAndFetchWorksheets();
    }
    };

    const getDateRangesForMonthNames = (monthNames)=> {
        const currentYear = moment().year();
        const dateRanges = monthNames.map(monthName => {
            const month = moment().month(monthName).format('MM'); // Convert month name to month number
            const startDate = moment(`${selectedYears.value}-${month}-01`).startOf('month').format("YYYY-MM-DD");
            const endDate = moment(`${selectedYears.value}-${month}-01`).endOf('month').format("YYYY-MM-DD");
            return { startDate, endDate };
        });
        return dateRanges;
    };

      const isLessThanWeekOld = (dateString)=> {       
        const date = moment(dateString);
        const now = moment();
        const difference = now.diff(date, 'days') < 7;
        return difference;
    }

    // Watch for changes in the userDetails prop
    watch(
    () => props.userDetails,
    (newUserDetails, oldUserDetails) => {
        if (newUserDetails && newUserDetails.user_roles) {
        // Call the function to check user role and fetch worksheets
        checkUserRoleAndFetchWorksheets();
        }
    }
    );

    watch(
    () => props.optionsResponse,
    (newVal, oldVal) => {
        if (newVal) {
        setTimeout(() => {
            window.HSStaticMethods.autoInit();
            console.log("Preline initialised in worksheets.vue");

            // Initialize the select element for cohorts
            const cohortsSelectElement = window.HSSelect.getInstance('#cohortsSelect');
            if (cohortsSelectElement) {
            cohortsSelectElement.on('change', (value) => {
                console.log('Select value changed:', value);
                selectedCohorts.value = value
                // fetchWorksheets();
                checkUserRoleAndFetchWorksheets();
            });
            } else {
            console.log('AdvancedSelect cohorts instance not found');
            }

            // Initialize the select element for competencies
            const competenciesSelectElement = window.HSSelect.getInstance('#competenciesSelect');
            if (competenciesSelectElement) {
            competenciesSelectElement.on('change', (value) => {
                console.log('Select value changed:', value);
                selectedCompetencies.value = value
                // fetchWorksheets();
                checkUserRoleAndFetchWorksheets();
            });
            } else {
            console.log('AdvancedSelect competencies instance not found');
            }

            // Initialize the select element for months
            const monthsSelectElement = window.HSSelect.getInstance('#monthsSelect');
            if (monthsSelectElement) {
            monthsSelectElement.on('change', (value) => {
                console.log('Select value changed:', value);
                // selectedMonths.value = value
                dateFilters.value = getDateRangesForMonthNames(value)
                // fetchWorksheets();
                checkUserRoleAndFetchWorksheets();
            });
            } else {
            console.log('AdvancedSelect competencies instance not found');
            }


            // Initialize the select element for years
            const yearsSelectElement = window.HSSelect.getInstance('#yearsSelect');
            if (yearsSelectElement) {
            yearsSelectElement.on('change', (value) => {
                console.log('Select years value changed:', value);
                selectedYears.value = value
                // dateFilters.value = getDateRangesForMonthNames(value)
                // fetchWorksheets();
                checkUserRoleAndFetchWorksheets();
            });
            } else {
            console.log('AdvancedSelect competencies instance not found');
            }

             // Initialize the select element for status, Done/not Done filter
             const statusSelectElement = window.HSSelect.getInstance('#statusSelect');
                if (statusSelectElement) {
                statusSelectElement.on('change', (value) => {
                    console.log('Select value changed:', value);
                   
                    // Assign null when both "Done" & "Not Done" options are selected or when no option is selected  
                    const isEmptySelection = value.length === 0;
                    const isBothOptionsSelected = value.includes('0') && value.includes('1');
                    
                    selectedStatus.value = isEmptySelection || isBothOptionsSelected ? null : value[0];
    
                    // selectedStatus.value = value.length === 0 || (value.includes('0') && value.includes('1')) ? null : value[0];
                    checkUserRoleAndFetchWorksheets();
                });
                } else {
                console.log('AdvancedSelect status instance not found');
                }


                // Initialize the select element for years
                const sortSelectElement = window.HSSelect.getInstance('#sortingSelect');
                if (sortSelectElement) {
                    sortSelectElement.on('change', (value) => {
                    console.log('Select sorting filter value changed:', value);
                    selectedSort.value = value
                    checkUserRoleAndFetchWorksheets();
                });
                } else {
                    console.log('AdvancedSelect competencies instance not found');
                }


        }, 100);
        }
    },
    { immediate: true } // This ensures the watcher is triggered immediately if optionsResponse is already set
    );



</script>