export function findValueByName(arr, name, key) {
  if (!Array.isArray(arr)) return ''
  const foundObj = arr.find(obj => obj.name === name)
  return foundObj ? foundObj[key] : ''
}

export function findNameByValue(arr, value, key) {
  if (!Array.isArray(arr)) return ''
  const foundObj = arr.find(obj => obj[key].trim().toLowerCase() === value.trim().toLowerCase())
  return foundObj ? foundObj.name : ''
}

export function getFormattedDate(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.getMonth() + 1; // Months are zero-based, so add 1
  const year = date.getFullYear();

  const formattedDate = `${day}/${month}/${year}`;

  return formattedDate;
}

export function getTodayDateJSFormat() {
    // Get today's date
  let today = new Date();

  // Get the year, month, and day
  let year = today.getFullYear();
  let month = String(today.getMonth() + 1).padStart(2, '0'); // Months are zero-indexed, so add 1
  let day = String(today.getDate()).padStart(2, '0'); // Get the day and pad with zero if necessary

  // Combine into the desired format
  let formattedDate = `${year}-${month}-${day}`;
  return formattedDate;
}

export function goBackTwoDays(dateString) {
  // Assuming dateString is the date string "2024-03-25"
  const date = new Date(dateString);

  // Subtract 2 days from the date
  date.setDate(date.getDate() - 2);

  // Get the year, month, and day components of the new date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');

  // Format the result as "YYYY-MM-DD" string
  const twoDaysBeforeDateString = `${year}-${month}-${day}`;

  return twoDaysBeforeDateString
}

export function getDateFromPreviousMonth(dateString) {
  // Create a Date object from the given date string
  const date = new Date(dateString);
  
  // Subtract 1 month from the date
  date.setMonth(date.getMonth() - 1);
  
  // Get the year, month, and day components of the new date
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const day = String(date.getDate()).padStart(2, '0');
  
  // Format the result as "YYYY-MM-DD" string
  const previousMonthDate = `${year}-${month}-${day}`;
  
  return previousMonthDate;
};

export function isTwoWeeksFromStartOfMonth() {
  const today = new Date();
  const year = today.getFullYear();
  const month = today.getMonth();

  const startOfMonth = new Date(year, month, 1);

  // Find the first Monday of the month
  const dayOfWeek = startOfMonth.getDay();
  let firstMonday = new Date(startOfMonth);
  
  // Adjust the date to the first Monday (0 is Sunday, 1 is Monday, ..., 6 is Saturday)
  if (dayOfWeek !== 1) {
    // Calculate the difference to the first Monday
    const diffToMonday = (dayOfWeek === 0) ? 1 : (8 - dayOfWeek);
    firstMonday.setDate(startOfMonth.getDate() + diffToMonday);
  }

  // Calculate the date two whole weeks from the first Monday
  const twoWeeksLater = new Date(firstMonday);
  twoWeeksLater.setDate(firstMonday.getDate() + 14);

  // Check if today is past two weeks from the first Monday
  return today >= twoWeeksLater;
}

export function atLeastTwoMondaysAndFridaysPassed(dateString) {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = date.getMonth(); // getMonth() returns 0-based month index
  const day = date.getDate();

  let mondayCount = 0;
  let fridayCount = 0;

  for (let d = 1; d <= day; d++) {
      const currentDay = new Date(year, month, d).getDay();
      if (currentDay === 1) {
          mondayCount++;
      } else if (currentDay === 5) {
          fridayCount++;
      }

      // Early exit if both counts reach at least 2
      if (mondayCount >= 2 && fridayCount >= 2) {
          return true;
      }
  }

  return mondayCount >= 2 && fridayCount >= 2;
}


export function jsFormatDate(date) {
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
}

export function getWeekdays(dateString) {
  const inputDate = new Date(dateString);
  const dayOfWeek = inputDate.getDay();
  
  // Calculate the Monday of the same week
  const diffToMonday = dayOfWeek === 0 ? -6 : 1 - dayOfWeek;
  const monday = new Date(inputDate);
  monday.setDate(inputDate.getDate() + diffToMonday);
  
  // Generate the dates from Monday to Friday
  const weekdays = [];
  for (let i = 0; i < 5; i++) {
    const weekday = new Date(monday);
    weekday.setDate(monday.getDate() + i);
    weekdays.push(jsFormatDate(weekday));
  }
  
  return weekdays;
}

export function getNextWeekDate(dateString) {
  const inputDate = new Date(dateString);

  // Add 7 days to the date to get the same day in the next week
  const nextWeekDate = new Date(inputDate);
  nextWeekDate.setDate(inputDate.getDate() + 7);

  return jsFormatDate(nextWeekDate);
}

export function getLastWeekDate(dateString) {
  const inputDate = new Date(dateString);

  // Add 7 days to the date to get the same day in the next week
  const nextWeekDate = new Date(inputDate);
  nextWeekDate.setDate(inputDate.getDate() - 7);

  return jsFormatDate(nextWeekDate);
}

export function getOrdinalSuffix(day) {
  if (day > 3 && day < 21) return 'th'; // Handles 11th, 12th, 13th, etc.
  switch (day % 10) {
    case 1: return 'st';
    case 2: return 'nd';
    case 3: return 'rd';
    default: return 'th';
  }
}

// Function to format the date - Monday, July 1st
export function weekdayFormatDate2(date) {
  const dateObj = new Date(date)
  const options = { weekday: 'long', day: 'numeric', month: 'long' };
  const dateString = dateObj.toLocaleDateString('en-US', options);
  
  // Extract day to add ordinal suffix
  const day = dateObj.getDate();
  const ordinalSuffix = getOrdinalSuffix(day);

  // Replace numeric day with day + ordinal suffix
  return dateString.replace(day, `${day}${ordinalSuffix}`);
}

export function getWeekday(dateString) {
  const dateObj = new Date(dateString);
  return dateObj.toLocaleDateString('en-US', { weekday: 'long' });
}

export function weekdayFormatDate(date) {
  const dateObj = new Date(date);
  
  // Extract parts of the date
  const day = dateObj.getDate();
  const ordinalSuffix = getOrdinalSuffix(day);
  const weekday = dateObj.toLocaleDateString('en-US', { weekday: 'long' });
  const month = dateObj.toLocaleDateString('en-US', { month: 'long' });

  // Format the date string as "Monday, 21st July"
  return `${weekday}, ${day}${ordinalSuffix} ${month}`;
}

export function weekdayFormatDateShort(date) {
  const dateObj = new Date(date);
  
  // Extract parts of the date
  const day = dateObj.getDate();
  const ordinalSuffix = getOrdinalSuffix(day);
  const weekday = dateObj.toLocaleDateString('en-US', { weekday: 'short' });
  const month = dateObj.toLocaleDateString('en-US', { month: 'short' });

  // Format the date string as "Monday, 21st July"
  return `${weekday}, ${day}${ordinalSuffix} ${month}`;
}

export function formatDateWithSuffix(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'long' });

  const daySuffix = getOrdinalSuffix(day);

  return `${day}${daySuffix} ${month}`;
}

export function formatDateWithSuffixShort(dateString) {
  const date = new Date(dateString);

  const day = date.getDate();
  const month = date.toLocaleString('default', { month: 'short' });

  const daySuffix = getOrdinalSuffix(day);

  return `${day}${daySuffix} ${month}`;
}

export function isToday(dateString) {
  const date = new Date(dateString);
  const today = new Date();
  
  return date.getDate() === today.getDate() &&
         date.getMonth() === today.getMonth() &&
         date.getFullYear() === today.getFullYear();
}

export function isYesterday(dateString) {
  const date = new Date(dateString);
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  
  return date.getDate() === yesterday.getDate() &&
         date.getMonth() === yesterday.getMonth() &&
         date.getFullYear() === yesterday.getFullYear();
}

export function openDatePicker(event) {
  // return
  const input = event.target;
  const inputRect = input.getBoundingClientRect();
  
  // Get the click position relative to the input field
  const clickX = event.clientX - inputRect.left;
  console.log({ inputRect, clientX: event.clientX, clickX})

  // Only open the date picker if the click is on the right half of the input
  // if (clickX > inputRect.width / 2) {
  // around 108 px is the width of the date text in the input
  if (clickX > 108) {
    event.target.showPicker()
  }
}

export function getFinalUserTagList(comment, taggedUsers) {
  const newTaggedUsers = []
  taggedUsers.forEach((user) => {
    if (comment.includes(`@${user.value}`))
      newTaggedUsers.push(user)
  })

  return newTaggedUsers
}

export function truncateWithEllipsis(str, width) {
  // console.log("Ellipsis", {str, width})
  return str?.length > width ? str.slice(0, width) + '...' : str;
}

export function getNthAlphabet(n) {
  const alphabet = 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  
  if (n < 1 || n > 26) {
    return 'a';
  }
  return alphabet[n - 1]; // n-1 because index starts at 0
}

export function formatDateDotWithTime(dateString) {
    // Parse the date string to remove the milliseconds if needed
    const date = new Date(dateString.split('.')[0]);

    // Extract day, month, year, hour, and minute
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear().toString().slice(-2); // Get the last 2 digits of the year
    const hours = date.getHours().toString().padStart(2, '0');
    const minutes = date.getMinutes().toString().padStart(2, '0');

    // Format as 'DD.MM.YY hh:mm'
    return `${day}.${month}.${year} ${hours}:${minutes}`;
}

export async function timeoutPromise(promise, timeoutMs) {
  const startTime = Date.now();
  let timeoutHandle;
  const timeoutPromise = new Promise((_, reject) => {
    timeoutHandle = setTimeout(() => {
      reject(new Error('Timeout'));
    }, timeoutMs);
  });

  return Promise.race([
    promise,
    timeoutPromise
  ]).finally(() => {
    clearTimeout(timeoutHandle);
    console.log(`Total time: ${Math.floor((Date.now() - startTime) / 1000)} seconds`);
  });
};