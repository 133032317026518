<template>
  <div class="flex flex-col md:flex-row gap-8 w-[80%]">
    <div class="md:flex-auto md:basis-1/2 h-[14rem] lg:h-[16rem]">
      <BarChart :categories="competencies" :values="overallCounts" :color="colors" 
        :inverse="true" :showNames="true" :showYTick="false" barCategoryGap="5%"
        :yAxisLabelSize="8" />
      <p class="font-medium text-xs flex justify-center">All cohorts</p>
    </div>
    <!-- <div class="md:flex-auto md:basis-1/2 flex p-3 md:p-0 md:justify-center">
      <div class="space-y-1">
        <div class="flex gap-2" v-for="(comp, index) in competencies">
          <div :style="{ backgroundColor: colors[index] }" :class="`size-4 shrink-0`" ></div>
          <p class="text-[0.6rem] font-medium">{{ comp }}</p>
        </div>
      </div>
    </div> -->
  </div>

</template>

<script setup>
import { computed, inject } from 'vue';
import BarChart from '../../../charts/BarChart.vue';

const props = defineProps(['activityData'])

const cohortList = [
  "BigKids",
  "Bombils",
  "Bimblis",
  // "Batatas",
  "Batatas-1",
  "Batatas-2",
]

const competencies = [
  "Storytelling",
  "Critical Thinking",
  "Emotional Intelligence",
  "Fine Motor Skills",
  "Math & Numeracy",
  "Physical Wellness",
  "Reading & Writing",
  "Science Exploration",
  "Music & Rhythm",
  "Visual Arts",
  "Others",
]

const colors = ['#F5CCE8', '#9A79A6', '#7B2D26', '#FB4D3D', '#F18D01', '#FFC000', '#FFEBAE', '#8DB580', '#D3FCD5', '#70E4EF', '#345995']

const overallCounts = computed(() => {
  if (!props.activityData?.competency_counts) {
    const emptyArr = new Array(competencies.length).fill(0);
    return emptyArr;
  }
  return Object.entries(props.activityData.competency_counts['Overall']).map(([key, value]) => value);
})
</script>