<template>
  <div></div>
  <div @click.self="closeModal"
    class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto">
    <!-- Form container with white background -->
    <div ref="imagesContainer" class="bg-white rounded-lg shadow-md w-11/12 md:w-[70vw] h-auto max-h-[90%] min-h-[85%] md:min-h-auto md:max-h-[80vh] pl-2 pr-2 overflow-auto">

      <!-- Closing cross -->
      <div class="flex flex-row-reverse sticky md:top-7 top-5 md:mr-2 mr-3 z-50 cursor-pointer" @click="closeModal">
        <svg class="w-[22px] md:w-[27px]" viewBox="0 0 31 30" fill="none" xmlns="http://www.w3.org/2000/svg">
          <path d="M23.3542 7.54102L8.4375 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round"
            stroke-linejoin="round" />
          <path d="M8.4375 7.54102L23.3542 22.4578" stroke="#1F2937" stroke-width="1.24306" stroke-linecap="round"
            stroke-linejoin="round" />
        </svg>
      </div>

      <!-- Display heading date -->
      <div class="sticky top-0 font-semibold ml-0 pl-2 mb-2 mt-4 md:mt-5 text-3xl flex justify-between z-40 bg-white">
        <span>{{ formatHeadingDateRange(weeksImagesData.weekInfo.date_range)
          }}</span>
      </div>

      <!-- Timeline Bar container -->
      <div class="sticky top-8 z-40 bg-white w-full overflow-x-auto custom-scrollbar">
        <!-- Timeline Start -->
        <div class="flex flex-row mt-10 md:mt-7 mb-12">


          <!-- Iterate over timelineBarDaysWithDates to create each timeline segment -->
          <div v-for="(date, index) in timelineBarFullWeek" :key="`date-${index}`" class="flex items-center">
            <div class="relative flex items-center">
              <!-- Line with a specific length -->
              <div class="w-32 md:w-[18rem] border-t-2 border-gray-300"></div>

              <!-- Hollow Circle -->
              <div class="flex items-center">
                <div :ref="`highlightedCircle${index}`"
                  class="absolute w-6 h-6 border-2 border-[#FFD700] rounded-full bg-white flex justify-center items-center top-[-12px] left-1/2 transform -translate-x-1/2 bg-white"
                  :class="{ 'bg-white': date !== highlightedDate, 'bg-yellow-300': date === highlightedDate }">
                </div>
                <!-- Text directly below the Circle for each unique date -->
                <div
                  class="absolute mt-[65px] flex justify-center items-center ml-1 left-1/2 transform -translate-x-1/2 text-xs font-medium">
                  <!-- Display the date -->
                  {{ formatTimelineBarDate(date) }}
                </div>
              </div>
            </div>
          </div>


        </div>
        <!-- Timeline End -->


      </div>

      <!-- Date and labels -->
      <div class="md:flex md:gap-10 mt-5">
        <!-- Display date range -->
        <div class="font-medium mb-2 ml-2 text-xl">{{ weeksImagesData.weekInfo.date_range }}</div>

        <!-- Iterate through tags array and print label names -->
        <div>
          <span v-for="(tag, index) in uniqueTags" :key="index" :class="{
            'bg-[#FDD835]': !selectedTags.includes(tag.name),
            'bg-yellow-500': selectedTags.includes(tag.name)
          }"
            class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-md px-2 py-1 text-sm font-medium text-black bg-[#FDD835] cursor-pointer"
            @click="toggleTag(tag.name)">
            {{ tag.name }}
          </span>
        </div>
      </div>

      <!-- Display description -->
      <!-- <div class="ml-2 mt-2 mb-6">{{ weeksImagesData.weekInfo.first_three_images[0].description }}</div> -->

      <!-- Rendering Images -->
      <!-- Loop through each day/date -->
      <div v-for="(dayImages, dayIndex) in groupImagesByDay(filteredImages)" :key="`day-${dayIndex}`"
        class="day-images-container w-full mb-4">
        <!--Container for each day-->
        <!-- Display the day/date -->
        <h2 class="text-lg font-medium mb-2 ml-2 mt-2">{{ formatGroupedByDayDate(dayImages[0].image_date) }}</h2>
        <div class="flex flex-col md:flex-row flex-wrap">

          <!-- Loop through images for the current day/date --> <!-- md:h-[340px]-->
          <div v-for="(image, imageIndex) in dayImages" :key="`image-${dayIndex}-${imageIndex}`"
             :class="[
              'relative week-images-container flex flex-wrap md:overflow-hidden mb-3 md:mr-[1%]',
              image.is_video ? 'w-full md:w-[50%] lg:w-[57%]' : 'w-full md:w-[49%] lg:w-[32%]'
            ]">

            <!-- spinner logo -->
            <div v-if="isImageLoading && imageIndex < 1"
              class="absolute top-0 left-0 bg-black bg-opacity-10 flex items-center justify-center z-5 rounded-3xl w-full h-[21em] md:h-[15em] lg:h-[21em]">
              <div style="color: #FDD835"
                class="animate-spin inline-block size-12 border-[3px] border-current border-t-transparent text-yellow-600 rounded-full"
                role="status" aria-label="loading">
                <span class="sr-only">Loading...</span>
              </div>
            </div>
           
            <template v-if="image.is_video">
               <videoStreamer :videoUrl="image.video_s3_streaming_url"></videoStreamer>
             </template> 
             
             <template v-else>
               <img :src="image.timeline_url" class="rounded-3xl w-full h-[] md:h-[] lg:h-[] cursor-pointer" :alt="''"
                 @click="selectImage(image)" :ref="`image-${dayIndex}-${imageIndex}`" @load="handleImageLoad">
             </template>

            
          </div>
        </div>
      </div>

      <!-- Pagintion controls -->
      <div v-if=" pageNumber && totalPages" class="pagination-controls flex justify-center mt-4">
        <button @click="prevPage" :disabled="pageNumber === 1" class="px-4 py-2 border rounded mr-2" :class="{'opacity-50 cursor': pageNumber === 1}">
          Previous
        </button>

        <span class="px-4 py-2">{{ pageNumber }}/{{ totalPages }}</span>
        <button @click="nextPage" :disabled="pageNumber >= totalPages" class="px-4 py-2 border rounded ml-2" :class="{'opacity-50 cursor': pageNumber >= totalPages}">
          Next
        </button>
      </div>

      <!--Modal for Expanded view-->
      <div v-if="showModal" @click.self="closeExpandedImageModal"
        class="new-event-form fixed top-0 left-0 w-full h-full bg-black bg-opacity-85 flex flex-col items-center justify-center z-50 overflow-auto">

         <!-- Previous button for desktop -->
         <button @click="navigateImage('prev')" 
                    :disabled="isFirstImage"
                    class="hidden md:flex absolute left-6 md:left-24 top-1/2 transform -translate-y-1/2 bg-slate-300 hover:bg-slate-400 py-3.5 px-3.5 rounded-full"
                    :class="{ 'opacity-50 hover:bg-slate-300': isFirstImage }">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.12231 1.7566C9.15659 1.7908 9.1838 1.83142 9.20236 1.87615C9.22092 1.92088 9.23047 1.96883 9.23047 2.01726C9.23047 2.06568 9.22092 2.11363 9.20236 2.15836C9.1838 2.20309 9.15659 2.24372 9.12231 2.27792L4.96429 6.4352L9.12231 10.5925C9.15654 10.6267 9.18369 10.6673 9.20222 10.7121C9.22074 10.7568 9.23028 10.8047 9.23028 10.8531C9.23028 10.9015 9.22074 10.9495 9.20222 10.9942C9.18369 11.0389 9.15654 11.0796 9.12231 11.1138C9.08808 11.148 9.04744 11.1752 9.00272 11.1937C8.95799 11.2122 8.91006 11.2218 8.86165 11.2218C8.81324 11.2218 8.76531 11.2122 8.72058 11.1937C8.67586 11.1752 8.63522 11.148 8.60099 11.1138L4.18305 6.69586C4.14876 6.66166 4.12156 6.62103 4.103 6.5763C4.08444 6.53157 4.07489 6.48362 4.07489 6.4352C4.07489 6.38677 4.08444 6.33882 4.103 6.29409C4.12156 6.24937 4.14876 6.20874 4.18305 6.17454L8.60099 1.7566C8.63519 1.72231 8.67582 1.69511 8.72054 1.67655C8.76527 1.65799 8.81322 1.64844 8.86165 1.64844C8.91007 1.64844 8.95803 1.65799 9.00275 1.67655C9.04748 1.69511 9.08811 1.72231 9.12231 1.7566Z" fill="#1F2937" stroke="black" stroke-width="0.699351"/>
                </svg>
         </button>

         <!-- Next button for desktop -->
         <button @click="navigateImage('next')" 
                    :disabled="isLastImage"
                    class="hidden md:flex absolute right-6 md:right-24 top-1/2 transform -translate-y-1/2 bg-slate-300 hover:bg-slate-400 py-3.5 px-3.5 rounded-full"
                    :class="{ 'opacity-50 hover:bg-slate-300': isLastImage }">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66145 1.65364C4.6239 1.6911 4.5941 1.73561 4.57377 1.78461C4.55343 1.8336 4.54297 1.88613 4.54297 1.93918C4.54297 1.99223 4.55343 2.04475 4.57377 2.09375C4.5941 2.14275 4.6239 2.18725 4.66145 2.22472L9.21635 6.7788L4.66145 11.3329C4.62396 11.3704 4.59421 11.4149 4.57392 11.4639C4.55362 11.5129 4.54318 11.5654 4.54318 11.6184C4.54318 11.6715 4.55362 11.724 4.57392 11.773C4.59421 11.8219 4.62396 11.8665 4.66145 11.904C4.69895 11.9415 4.74347 11.9712 4.79246 11.9915C4.84145 12.0118 4.89396 12.0222 4.94699 12.0222C5.00002 12.0222 5.05253 12.0118 5.10152 11.9915C5.15052 11.9712 5.19503 11.9415 5.23253 11.904L10.0722 7.06434C10.1097 7.02688 10.1395 6.98237 10.1598 6.93337C10.1802 6.88438 10.1906 6.83185 10.1906 6.7788C10.1906 6.72575 10.1802 6.67323 10.1598 6.62423C10.1395 6.57523 10.1097 6.53073 10.0722 6.49326L5.23253 1.65364C5.19507 1.61608 5.15056 1.58629 5.10156 1.56595C5.05257 1.54562 5.00004 1.53516 4.94699 1.53516C4.89394 1.53516 4.84142 1.54562 4.79242 1.56595C4.74342 1.58629 4.69892 1.61608 4.66145 1.65364Z" fill="#1F2937" stroke="black"/>
                </svg>
         </button>

        <!-- Closing cross -->
        <div class="flex flex-row-reverse absolute right-3 md:right-24 top-4 md:top-10 z-50 cursor-pointer" @click="closeExpandedImageModal">
          <svg width="27" height="26" viewBox="0 0 27 26" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M19.5254 6.5L6.52539 19.5" stroke="white" stroke-width="1.70157" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M6.52539 6.5L19.5254 19.5" stroke="white" stroke-width="1.70157" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>

        </div> 

        <!-- Download Link -->
        <a :href="selectedImage.original_image_url" download
          class="absolute inline-flex left-6 md:left-10 top-3 md:top-10 hover:bg-slate-600 py-2 px-2 rounded ">
          <svg width="22" height="21" viewBox="0 0 33 32" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path
              d="M16.3854 24.0059L6.59375 14.2142L9.33541 11.3746L14.4271 16.4663V0.505859H18.3437V16.4663L23.4354 11.3746L26.1771 14.2142L16.3854 24.0059ZM4.63542 31.8392C3.55833 31.8392 2.63661 31.456 1.87025 30.6896C1.10389 29.9233 0.720056 29.0009 0.71875 27.9225V22.0475H4.63542V27.9225H28.1354V22.0475H32.0521V27.9225C32.0521 28.9996 31.6689 29.922 30.9025 30.6896C30.1362 31.4573 29.2138 31.8405 28.1354 31.8392H4.63542Z"
              fill="white" />
          </svg>
        </a>
        
        <!-- Image container with background -->
        <div class="rounded-lg shadow-md w-11/12 md:w-auto h-auto max-h-[94%] md:mt-8 overflow-hidden flex flex-col">

          <!-- Date -->
          <div class="text-white text-lg md:text-3xl font-medium mb-2 mt-8 md:mt-0 md:mb-8">{{
            formatExpandedImageDate(selectedImage.image_date) }}</div>

          <!-- Spinner logo for expanded image -->
          <div v-if="isExpandedImageLoading" class="absolute inset-0 flex items-center justify-center md:mt-12">
            <div style="color: #FDD835"
              class="animate-spin inline-block size-12 border-[3px] border-current border-t-transparent text-yellow-600 rounded-full"
              role="status" aria-label="loading">
              <span class="sr-only">Loading...</span>
            </div>
          </div>

          <!-- Image -->
          <img v-show="!isExpandedImageLoading":src="selectedImage.original_image_url" alt="Image" @load="onExpandedImageLoad"
            class="md:max-h-[72vh] md:max-w-[90vw] object-contain self-center mb-1">

          <!-- Tags, Description, Student names-->
          <div v-if="selectedImage && selectedImage.tags" class="text-2xl text-white">

            <!-- Render each tag's name -->
            <div v-for="(tag, index) in selectedImage.tags" :key="index"
              class="tag-badge ml-1 mr-1 mb-1 inline-block rounded-md px-2 py-1 text-sm font-medium text-white border border-[#FDD835] cursor-pointer">
              {{ tag.name }}
            </div>
            
            <!-- Render each custom tag's name -->
            <div v-for="(customTag, index) in customTagsArrayForExpandedImage" :key="`custom-${index}`"
                class="tag-badge ml-1 mr-1 mb-1 inline-block rounded-md px-2 py-1 text-sm font-medium text-white border border-[#FDD835] cursor-pointer">
              {{ customTag }}
            </div>

            <!-- Description -->
            <div class="text-[14px] overflow-auto">{{ selectedImage.description }}</div>

            <!--Document Name -->
            <!-- <div v-if="userRoles.includes('System Manager')" class="text-lg md:text-xl mt-2">Document id: {{ selectedImage.name }}</div> -->

            <div v-for="(student, index) in selectedImage.students" class="tag-badge ml-1 mr-1 mb-2 inline-block rounded-md px-2 py-1 text-sm font-medium text-white border border-[#FDD835] cursor-pointer">
            {{ student.student_name }}
            </div>

            <!-- <div class="">{{ selectedImage.custom_tags }}</div> -->

          </div>

        </div>
        
        <!-- Previous/next buttons for mobile -->
        <div class="md:hidden flex gap-x-8" :class="{'mt-32':isExpandedImageLoading}">
            <!-- Previous button -->
            <button @click="navigateImage('prev')" 
                    :disabled="isFirstImage"
                    class=" bg-slate-300 hover:bg-slate-400 py-3.5 px-3.5 rounded-full"
                    :class="{ 'opacity-50 hover:md:bg-slate-300': isFirstImage }">
              <svg width="13" height="13" viewBox="0 0 13 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M9.12231 1.7566C9.15659 1.7908 9.1838 1.83142 9.20236 1.87615C9.22092 1.92088 9.23047 1.96883 9.23047 2.01726C9.23047 2.06568 9.22092 2.11363 9.20236 2.15836C9.1838 2.20309 9.15659 2.24372 9.12231 2.27792L4.96429 6.4352L9.12231 10.5925C9.15654 10.6267 9.18369 10.6673 9.20222 10.7121C9.22074 10.7568 9.23028 10.8047 9.23028 10.8531C9.23028 10.9015 9.22074 10.9495 9.20222 10.9942C9.18369 11.0389 9.15654 11.0796 9.12231 11.1138C9.08808 11.148 9.04744 11.1752 9.00272 11.1937C8.95799 11.2122 8.91006 11.2218 8.86165 11.2218C8.81324 11.2218 8.76531 11.2122 8.72058 11.1937C8.67586 11.1752 8.63522 11.148 8.60099 11.1138L4.18305 6.69586C4.14876 6.66166 4.12156 6.62103 4.103 6.5763C4.08444 6.53157 4.07489 6.48362 4.07489 6.4352C4.07489 6.38677 4.08444 6.33882 4.103 6.29409C4.12156 6.24937 4.14876 6.20874 4.18305 6.17454L8.60099 1.7566C8.63519 1.72231 8.67582 1.69511 8.72054 1.67655C8.76527 1.65799 8.81322 1.64844 8.86165 1.64844C8.91007 1.64844 8.95803 1.65799 9.00275 1.67655C9.04748 1.69511 9.08811 1.72231 9.12231 1.7566Z" fill="#1F2937" stroke="black" stroke-width="0.699351"/>
                </svg>
            </button>

            <!-- Next button -->
            <button @click="navigateImage('next')" 
                    :disabled="isLastImage"
                    class="bg-slate-300 hover:bg-slate-400 py-3.5 px-3.5 rounded-full"
                    :class="{ 'opacity-50 hover:md:bg-slate-300': isLastImage }">
              <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path fill-rule="evenodd" clip-rule="evenodd" d="M4.66145 1.65364C4.6239 1.6911 4.5941 1.73561 4.57377 1.78461C4.55343 1.8336 4.54297 1.88613 4.54297 1.93918C4.54297 1.99223 4.55343 2.04475 4.57377 2.09375C4.5941 2.14275 4.6239 2.18725 4.66145 2.22472L9.21635 6.7788L4.66145 11.3329C4.62396 11.3704 4.59421 11.4149 4.57392 11.4639C4.55362 11.5129 4.54318 11.5654 4.54318 11.6184C4.54318 11.6715 4.55362 11.724 4.57392 11.773C4.59421 11.8219 4.62396 11.8665 4.66145 11.904C4.69895 11.9415 4.74347 11.9712 4.79246 11.9915C4.84145 12.0118 4.89396 12.0222 4.94699 12.0222C5.00002 12.0222 5.05253 12.0118 5.10152 11.9915C5.15052 11.9712 5.19503 11.9415 5.23253 11.904L10.0722 7.06434C10.1097 7.02688 10.1395 6.98237 10.1598 6.93337C10.1802 6.88438 10.1906 6.83185 10.1906 6.7788C10.1906 6.72575 10.1802 6.67323 10.1598 6.62423C10.1395 6.57523 10.1097 6.53073 10.0722 6.49326L5.23253 1.65364C5.19507 1.61608 5.15056 1.58629 5.10156 1.56595C5.05257 1.54562 5.00004 1.53516 4.94699 1.53516C4.89394 1.53516 4.84142 1.54562 4.79242 1.56595C4.74342 1.58629 4.69892 1.61608 4.66145 1.65364Z" fill="#1F2937" stroke="black"/>
                </svg>
            </button>
        </div>    

      </div>


    </div>
  </div>
</template>


<script>

import moment from 'moment';
import videoStreamer from '../videoUpload/videoStreamer.vue';

export default {
  inject: ["$call"], // function provided by doppio library 
  props: {
    weeksImagesData: {
      type: Object,
      required: true,
    },
    existingAlbumsData: {
      type: Object,
      required: true,
    },
    selectedStudents: {
      type: Array,
      required: false,
    },
  },

  components: {
    videoStreamer
  },
  data() {
    return {
      selectedTags: [],
      showModal: false, // To control the modal's visibility
      selectedImage: null, // To store the selected image's data
      timelineBarDaysWithDates: [],
      timelineBarFullWeek: [],
      highlightedDate: {},
      isImageLoading: true,

      pageNumber: 1,
      pageSize: 10,  
      totalPages: null,
      totalImages: null,
      currentImageIndex: 0,
      isExpandedImageLoading: true,
    };
  },
  mounted() {
    console.log("This is the existing album data recieved in timeline view component", this.existingAlbumsData)
    this.albumsDataWithUniqueDates();
    console.log("This is weeks Image data recieved in time line component on mounting",this.weeksImagesData)
    this.fetchTimelineImages();
    this.$nextTick(() => {
      const imagesContainer = this.$refs.imagesContainer;
      if (imagesContainer) {

        const throttledScroll = this.throttle(this.handleScrollForHighlight, 100); // Assuming you're using a throttled version
        imagesContainer.addEventListener('scroll', throttledScroll);

        // imagesContainer.addEventListener('scroll', this.handleScrollForHighlight);
        console.log("Image scrolled, calling handle scroll----------------")
        // Call the handleScroll function once here to set the initial highlight
        this.handleScrollForHighlight();
      }
    });
    this.$nextTick(() => {
      for (let i = 0; i < this.timelineBarFullWeek.length; i++) {
        if (this.timelineBarFullWeek[i] === this.highlightedDate) { // Adjust this check to directly target highlightedDate
          const highlightedCircle = this.$refs[`highlightedCircle${i}`];
          if (highlightedCircle && highlightedCircle[0]) {
            highlightedCircle[0].scrollIntoView({
              behavior: 'smooth',
              block: 'center',
              inline: 'center'
            });
            break; // This now makes sense because we're looking specifically for highlightedDate
          }
        }
      }
    });

  },
  computed: {

    uniqueTags() {
      // Flatten the array of tags from each image into a single array, defaulting to an empty array if tags is undefined
      const allTags = this.weeksImagesData.weekInfo.first_three_images.flatMap(image => image.tags || []);

      // Extract custom tags from each image in first_three_images
      const customTagsArray = this.weeksImagesData.weekInfo.first_three_images.flatMap(image => {
        return image.custom_tags
            ? image.custom_tags.split(',').map(tag => tag.trim())
            : [];
      });

      // Create tag objects for each custom tag
      const customTagObjects = customTagsArray.map(tag => ({ name: tag, isCustom: true }));

      // Combine all tags from images and custom tags
      const combinedTags = [...allTags, ...customTagObjects];

      // Use a Map to filter out unique tags by tag.name
      const uniqueTagsMap = new Map();

      // Iterate through all combined tags and add them to the Map,
      // this automatically ensures uniqueness since Map keys are unique
      combinedTags.forEach(tag => {
          if (!uniqueTagsMap.has(tag.name)) {
              uniqueTagsMap.set(tag.name, tag);
          }
      });

      return Array.from(uniqueTagsMap.values());
  },

  filteredImages() {
    if (!this.selectedTags.length) return this.weeksImagesData.images; // Return all images if no tags are selected

    return this.weeksImagesData.images.filter((image) => {
      // Default to an empty array if tags is undefined
      const tags = image.tags || [];
      // Extract custom tags from the image and convert them to an array
      const customTags = image.custom_tags ? image.custom_tags.split(',').map(tag => tag.trim()) : [];

      // Check if the image's tags or custom tags contain any of the selected tags
      const hasTagInNormalTags = tags.some((tag) => this.selectedTags.includes(tag.name));
      const hasTagInCustomTags = customTags.some((tag) => this.selectedTags.includes(tag));

      return hasTagInNormalTags || hasTagInCustomTags;
    });
  },

  customTagsArrayForExpandedImage() {
    // Split the custom_tags string into an array if it exists, otherwise return an empty array
    return this.selectedImage && this.selectedImage.custom_tags 
      ? this.selectedImage.custom_tags.split(',').map(tag => tag.trim())
      : [];
  },
  

    // For disabling the next and previous button at the end and start of images
    isFirstImage() {
      return this.currentImageIndex === 0;
    },
    isLastImage() {
      return this.currentImageIndex === this.filteredImages.length - 1;
    }

  },
  methods: {

    async fetchTimelineImages() {
      console.log("fetch timeline images called")
      console.log("THis is prop images object+++++++++++++++++++++++",this.weeksImagesData)
      const weekNumber = this.weeksImagesData.weekInfo.week
      try {
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.fetch_timeline_images',{
          week_number:weekNumber,
          student_ids:this.selectedStudents,
          page_number: this.pageNumber, 
          page_size: this.pageSize
        });

        // Assuming the response structure contains the role in response.message.role
        if (response) {
          console.log("these are fetched timeline images++++++++++++",response)
          
          this.weeksImagesData.images = response.images
          console.log ("L:This is weeks images data after assigning fetched timeline images",this.weeksImagesData)

          this.totalImages = response.pagination.total_images;
          this.totalPages = Math.ceil(this.totalImages / this.pageSize);
          }
      } catch (error) {
        console.error('Error fetching Timeline images', error);
      }
    },

    handleImageLoad() {
     this.isImageLoading = false
    },
    onExpandedImageLoad(){
      console.log("w:on expanded image load called")
      this.isExpandedImageLoading= false
    },
    groupImagesByDay(images) {
      const groupedImages = {};
      // Group images by image_date
      images.forEach(image => {
        const date = moment(image.image_date).format('YYYY-MM-DD');
        if (!groupedImages[date]) {
          groupedImages[date] = [];
        }
        // Add isImageLoading property to each image
        const imageWithLoading = {
          ...image,
          isImageLoading: true // Initialize every image with a loading state
        };
        groupedImages[date].push(imageWithLoading);
      });

      // Convert object to array of arrays
      const groupedImagesArray = Object.values(groupedImages);

      return groupedImagesArray;
    },

    formatGroupedByDayDate(datetimeString) {
      // Parse the datetime string using Moment.js
      const parsedDate = moment(datetimeString);

      // Format the parsed date to the desired format
      const formattedDate = parsedDate.format('ddd, MMM DD');

      return formattedDate;
    },

    // Pagination controls
    prevPage() {
      if (this.pageNumber > 1) {
        this.pageNumber--;
        this.fetchTimelineImages();  // Method to fetch images with updated page number
      }

      //scroll the modal to the top
      const imagesContainer = this.$refs.imagesContainer;
      if (imagesContainer) {
        imagesContainer.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },

    nextPage() {
      if (this.pageNumber < this.totalPages) {
        this.pageNumber++;
        this.fetchTimelineImages();
      }

      //scroll the modal to the top
      const imagesContainer = this.$refs.imagesContainer;
      if (imagesContainer) {
        imagesContainer.scrollTo({
          top: 0,
          behavior: 'smooth'
        });
      }
    },

    handleScrollForHighlight() {
      // console.log('handleScrollForHighlight called');
      let closestImageTop = Infinity;
      let topImageDate = null;

      this.groupImagesByDay(this.filteredImages).forEach((dayImages, dayIndex) => {
        dayImages.forEach((weekData, imageIndex) => {
          const imageRef = this.$refs[`image-${dayIndex}-${imageIndex}`];

          if (imageRef && imageRef.length > 0) {
            const rect = imageRef[0].getBoundingClientRect();
            // console.log(`Image ${dayIndex}-${imageIndex}: Top: ${rect.top}, Bottom: ${rect.bottom}`);

            if (rect.top >= 0 && rect.top < closestImageTop) {
              closestImageTop = rect.top;
              topImageDate = weekData.image_date;
            }
          } else {
            // console.log(`Image ${dayIndex}-${imageIndex}: No element found in $refs`);
          }
        });
      });

      if (topImageDate !== null) {
        this.highlightedDate = moment(topImageDate).format('YYYY-MM-DD');
        // this.scrollToHighlightedCircle(this.highlightedDate);
        console.log("this is top Image date%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%%", this.highlightedDate);
      } else {
        console.log("No top image found");
      }
    },
    throttle(func, limit) {
      let inThrottle;
      return function () {
        const args = arguments;
        const context = this;
        if (!inThrottle) {
          func.apply(context, args);
          inThrottle = true;
          setTimeout(() => inThrottle = false, limit);
        }
      };
    },
   
    albumsDataWithUniqueDates() {
      let uniqueDates = []; // Initialize an empty array to store unique dates
      // console.log("This is weeks images data:", this.weeksImagesData);

      //  Ensure that weeksImagesData is an array
      if (Array.isArray(this.weeksImagesData.images)) {
        this.weeksImagesData.images.forEach(image => {
          // Check if image_date is not null or undefined before trying to split it
          if (image.image_date) {
            const datePart = image.image_date.split(' ')[0]; //we only want the date part, not the time
            uniqueDates.push(datePart);
          }
        });
        // Filter unique dates from the accumulated uniqueDates array
        uniqueDates = Array.from(new Set(uniqueDates));

        this.timelineBarDaysWithDates = uniqueDates; // Store the final unique dates in your component's data
        console.log("These are unique days:", this.timelineBarDaysWithDates);
        this.getWeekDaysFromFirstDate();
      }
    },
    // Function that takes an array and returns all days of the week for the first date in the array
    getWeekDaysFromFirstDate() {
      if (this.timelineBarDaysWithDates.length === 0) {
        return []; // Return an empty array if the input array is empty
      }

      const weekDays = [];
      const firstDate = moment(this.timelineBarDaysWithDates[0]); // Convert the first date in the array to a Moment.js date object
      const startOfWeek = firstDate.clone().startOf('week'); // Find the start of the week for the first date

      for (let i = 0; i < 7; i++) { // Assuming a 7-day week
        const day = startOfWeek.clone().add(i, 'days'); // Get each day of the week
        weekDays.push(day.format('YYYY-MM-DD')); // Format each day as 'YYYY-MM-DD' and add to the weekDays array
      }
      this.timelineBarFullWeek = weekDays;
    },
    formatTimelineBarDate(dateString) {

      return moment(dateString).format('dddd, Do');
    },
    formatDates(weekString) {

      if (!weekString) {
        return '';
      }
      const [year, weekNumber] = weekString.split('-');
      if (!year || !weekNumber) {
        return ' Undefined';
      }

      // Check each step to isolate the issue
      let date = moment().year(year);
      date = date.week(weekNumber);
      const startOfWeek = date.startOf('isoWeek');
      const endOfWeek = moment(startOfWeek).endOf('isoWeek');

      // Format the start and end of the week
      const formattedStart = startOfWeek.format('Do'); // e.g., "25th"
      const formattedEnd = endOfWeek.format('Do MMMM'); // e.g., "31st March"

      return `${formattedStart}-${formattedEnd}`;
    },
    formatHeadingDateRange(dateRange) {

      // Split the date range string into start and end dates
      const [startString, endString] = dateRange.split(' - ');


      // Parse the start and end dates using Moment.js
      const startDate = moment(startString, 'MMMM D');
      const endDate = moment(endString, 'MMMM D, YYYY');

      // console.log("Parsed Start Date:", startDate.format('MMMM, YYYY'));
      // console.log("Parsed End Date:", endDate.format('MMMM, YYYY'));

      // Format the start date as 'Month, Year' e.g., 'March, 2023'
      const formattedEndDate = endDate.format('MMMM, YYYY');

      // If start month and end month are the same, return formatted start date
      if (startDate.month() === endDate.month()) {

        return formattedEndDate;
      } else {
        // If start month and end month are different, also include end month
        return `${startDate.format('MMMM')} - ${endDate.format('MMMM, YYYY')}`;
      }
    },


    formatExpandedImageDate(dateString) {
      const formattedDate = moment(dateString).format('dddd, Do MMMM YYYY');
      return formattedDate

    },
    toggleTag(tagName) {
      console.log("Tag clicked", tagName)
      const index = this.selectedTags.indexOf(tagName);
      if (index > -1) {
        this.selectedTags.splice(index, 1); // Remove tag if already selected
      } else {
        this.selectedTags.push(tagName); // Add tag if not already selected
      }
      console.log("this is selected tags array", this.selectedTags)

    },
    filteredImages() {
      // A
      // Assuming weeksImagesData.weekInfo.date_range contains the date range for the current week
      const currentWeekDateRange = this.weeksImagesData.weekInfo.date_range;

      // Filter existingAlbumsData to include only weeks within the same month as the current week
      const weeksInMonth = this.existingAlbumsData.filter(weekData =>
        this.isSameMonth(weekData.date_range, currentWeekDateRange)
      );

      console.log("These are the filtered Weeks in month in timeline view component", weeksInMonth)

      return weeksInMonth;
    },
    // This method checks if the provided week's date range falls within the same month as the current week's date range
    isSameMonth(weekDateRange, currentWeekDateRange) {
      console.log("This is the weekdate range:", weekDateRange, "This is current week date range:", currentWeekDateRange)
      // Split the date ranges into start and end dates
      const [weekStart, weekEnd] = weekDateRange.split(' - ');
      const [currentWeekStart] = currentWeekDateRange.split(' - ');

      // Parse the dates
      const weekStartDate = new Date(weekStart);
      const weekEndDate = new Date(weekEnd);
      const currentWeekStartDate = new Date(currentWeekStart);

      // Check if the month and year of the start or end of the week matches the current week's start date
      return (
        (weekStartDate.getMonth() === currentWeekStartDate.getMonth() &&
          weekStartDate.getFullYear() === currentWeekStartDate.getFullYear()) ||
        (weekEndDate.getMonth() === currentWeekStartDate.getMonth() &&
          weekEndDate.getFullYear() === currentWeekStartDate.getFullYear())
      );
    },
    
    // Method to handle image click and fetching presigned original image url for Expansion 
    async selectImage(weekData) {
      console.log("This is the week data when image is clicked for expansion in timeline view", weekData)
      this.selectedImage = weekData; // Store the selected image's data
      
      try {
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.get_expanded_original_image_url',{
          doc_id: weekData.name
        });
        
        if (response) {
          this.selectedImage = { ...this.selectedImage, original_image_url: response };
          console.log("response",response)
          console.log("this is selected image after assigning response",this.selectedImage)
        }
      } catch (error) {
        console.error('Error fetching Original image link for expanded view:', error);
      }

      
      this.showModal = true; // Show the modal
    },
    
    async selectImage(weekData) {
      this.currentImageIndex = this.filteredImages.findIndex(img => img.name === weekData.name);
      await this.fetchAndDisplayImage(weekData);
    },

    async fetchAndDisplayImage(weekData) {
      this.isExpandedImageLoading = true;
      try {
        const response = await this.$call('curriculum_planner.curriculum_planner.doctype.upload_images.upload_images.get_expanded_original_image_url', {
          doc_id: weekData.name
        });
        
        if (response) {
          this.selectedImage = { ...weekData, original_image_url: response };
          this.showModal = true;
          
        }
      } catch (error) {
        console.error('Error fetching Original image link for expanded view:', error);
        
      }
    },

    async navigateImage(direction) {
      if (direction === 'next' && !this.isLastImage) {
        this.currentImageIndex++;
      } else if (direction === 'prev' && !this.isFirstImage) {
        this.currentImageIndex--;
      }
      await this.fetchAndDisplayImage(this.filteredImages[this.currentImageIndex]);
    },

    closeModal() {
      this.$emit('close-modal');
    },
    closeExpandedImageModal() {
      this.showModal = false;
    },
  },

}
</script>

<style scoped>
/* Tailwind CSS with custom pseudo-element styling */
.connector-line::after {
  content: '';
  position: absolute;
  left: 50%;
  /* Center the line horizontally */
  transform: translateX(-50%);
  width: 1px;
  /* Line thickness */
  background-color: #D1D5DB;
  /* Example color - adjust as needed */
  top: calc(50% + 0.5rem);
  /* Adjust starting point to be below the circle */
  bottom: calc(50% + 0.5rem);
  /* Adjust ending point to be above the bottom circle */
  z-index: 0;
  /* Ensure line is behind the circles */
}

.connector-line .size-7 {
  position: relative;
  z-index: 1;
  /* Ensure circle icon is above the line */
}

/* Custom scrollbar styling */
.custom-scrollbar::-webkit-scrollbar {
  width: 12px;
  height: 0px;
}

.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Track color */
}

.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
  /* Thumb color */
  border-radius: 6px;
  /* Rounded corners */
}

.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
  /* Hover state color */
}
</style>