<template>
  <div class="mx-2 md:mx-0 rounded max-h-full overflow-auto md:overflow-visible md:h-auto md:max-h-auto">
   
   <div class="border">
      <!-- Header Row -->
      <div class="flex justify-between font-medium">
        <div class="w-1/3 py-1 pl-3">Name</div>
        <div class="w-1/3 py-1 text-center">Email</div>
        <div class="w-1/3 py-1 text-center">Roles</div>
      </div>

      <!-- Data Rows -->
      <div v-for="(educator,index) in educatorsList" :key="educator.educator_id":class="{'bg-[#FFFBE8]': index % 2 === 0}"
      class="flex flex-row border-t text-sm cursor-pointer md:justify-between" @click="selectEducatorForEdit(educator)">
        <div class="w-1/3 md:w-[32%] pl-3 py-2 truncate">{{ educator.educator_name }}</div>
        <div class="w-1/3 md:w-[32%] py-2 truncate md:text-center">{{ educator.user_link }}</div>
        <div v-if="educator.roles" class="w-1/3 md:w-[32%] text-center pl-3 pr-1 py-2 text-slate-500 font-medium">
          {{ educator.roles.map(role => role === 'System Manager' ? 'Admin' : role).sort((a, b) => (a === 'Admin' ? -1 : b === 'Admin' ? 1 : 0)).join(', ') }}       
        </div>
      </div>
    </div>


    <!-- Pagination Controls -->
    <div v-if="educatorsList.length>0" class="justify-end gap-x-3 flex items-center w-full px-3 mt-2">
        <button @click="goToPreviousPage" :disabled="currentPage === 1" class="text-xl font-semibold">&larr;</button>
        <span>Page {{ currentPage }} of {{ totalPages }}</span>
        <button @click="goToNextPage" :disabled="currentPage === totalPages" class="text-xl font-semibold">&rarr;</button>
    </div>
      
  </div>

   


    <!-- Spinner logo -->
    <div v-if="isLoading" class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-20 flex items-center justify-center z-50">
        <div class="relative inline-block w-16 h-16" role="status" aria-label="loading">
            <div class="absolute inset-0 bg-conic-gradient rounded-full animate-spin spinner-mask" style="--spinner-thickness: 8px;"></div>
            <span class="sr-only">Loading...</span>
        </div>
    </div>


    <EditEducatorForm v-if="Object.keys(selectedEducator).length>0" :educator="selectedEducator" @close="selectedEducator = {}" @update-success="handleUpdateSuccess"></EditEducatorForm>
    
</template>

<script setup>
import { ref, onMounted, inject,watch } from 'vue';
import EditEducatorForm from './EditEducatorForm.vue';

// Inject the $call method
const $call = inject('$call');

const props = defineProps({
  reRenderCounter: {
    type: Number,
    default: 0
  }
});




const educatorsList = ref([]); // Store fetched educators
const currentPage = ref(1);
const pageSize = ref(20);
const isLoading = ref(true);
const totalPages = ref(0); // Total pages from API
const selectedEducator = ref({});


const selectEducatorForEdit = (educator) => {
  selectedEducator.value = educator;
};


onMounted(() => {
  fetchEducatorsProfileList();
});



const fetchEducatorsProfileList = async () => {
  try {
    const response = await $call('curriculum_planner.curriculum_planner.common.educator.api.get_all_educators_list', {
      page_number: currentPage.value,
      page_size: pageSize.value
    });
    console.log("Educator data fetch response",response)
    educatorsList.value = response.educators; // Store educators list in ref
    totalPages.value = response.pagination.total_pages; 
    isLoading.value=false;
  } catch (error) {
    console.error('Error fetching educators list:', error);
  }
};

// Watch for changes in reRenderCounter prop
watch(() => props.reRenderCounter, (newValue) => {
  fetchEducatorsProfileList();
});
  
  // Methods to navigate between pages
  const goToNextPage = () => {
      if (currentPage.value < totalPages.value) {
          currentPage.value++;
          fetchEducatorsProfileList();
      }
  };

  const goToPreviousPage = () => {
      if (currentPage.value > 1) {
          currentPage.value--;
          fetchEducatorsProfileList();
      }
  };

  const handleUpdateSuccess= ()=>{
    fetchEducatorsProfileList();
  }

</script>


<style scoped>

/* Styling for loading spinner */
.bg-conic-gradient {
  background: conic-gradient(from 90deg at 50% 50%, #FFCE31 0deg, rgba(255, 206, 49, 0.2) 294deg, rgba(255, 206, 49, 0) 360deg);
 }

.spinner-mask {
  --spinner-thickness: 3px;
  mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
  -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
}


</style>
