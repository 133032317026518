<template>
    <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self="closeComponent">
      <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97%] max-w-[450px] overflow-auto">
        <!-- Cross button -->
        <div class="sticky top-6 mr-5 flex justify-end z-10">
          <button @click="closeComponent" class="border-black">
            <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
          </button>
        </div>
        
        <!-- Content container -->
        <div class="w-full flex flex-col px-4">
          <div class="font-semibold text-[22px] mt-2 mb-6 px-2 md:px-0">
           Add New Parent
          </div>
          
          <!-- Form -->
          <form @submit.prevent="createNewParent" class="mb-6 px-1">
            <!-- Name -->
            <div class="mb-7">
              <label for="parentName" class="block text-sm font-medium">Name</label>
              <input v-model="parentName" type="text" id="parentName" required class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
  
            <!-- Email -->
            <div class="mb-7">
              <label for="email" class="block text-sm font-medium">Email</label> 
              <input type="text" id="email" v-model="email" required class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
              <div v-if="isEmailInvalid" class="text-red-500 text-sm mt-1">Please enter a valid email address.</div>
            
            </div>
  
            <!-- Phone Number -->
            <div class="mb-7">
              <label for="phoneNumber" class="block text-sm font-medium">Phone Number</label>
              <input v-model="phoneNumber" type="text" id="phoneNumber" class="mt-1.5 block w-full rounded-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50">
            </div>
            <div v-if="isNumberInvalid" class="text-red-500 text-sm mt-1">Please enter a valid country code (e.g., +91) followed by a 10-digit phone number.</div>
  

            <!-- Role -->
            <!-- Multi-Select Role Dropdown -->
              <div v-if="availableRoles" class="mb-7">
                <label for="roleSelect" class="block text-sm font-medium">Role</label>
                <div class="relative mt-1.5 cursor-pointer rounded">
                  <select  v-model="selectedRoles"  id="roleSelect"  ref="roleSelect" :key="roleSelectKey"  multiple data-hs-select='{
                  "placeholder": "Role",
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                  }' 
                    class="hidden">
                    <option v-for="role in availableRoles" :key="role" :value="role">
                      {{ role }}
                    </option>
                  </select>
                  <div class="absolute top-1/2 end-3 -translate-y-1/2">
                    <svg class="flex-shrink-0 w-3.5 h-3.5 text-gray-500 dark:text-gray-500" xmlns="http://www.w3.org/2000/svg"
                      width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                      stroke-linecap="round" stroke-linejoin="round">
                      <path d="m7 15 5 5 5-5" />
                      <path d="m7 9 5-5 5 5" />
                    </svg>
                  </div>
                </div>
              </div>

            <!-- Children Field -->
            <!-- Children Multi-Select Dropdown -->
            <div class="mb-7">
              <label for="childrenSelect" class="block text-sm font-medium">Children</label>
              <div class="relative mt-1.5 cursor-pointer rounded">
                <select v-model="selectedChildren" id="childrenSelect" ref="childrenSelect" :key="childrenSelectKey" multiple data-hs-select='{
                  "hasSearch": true,
                  "placeholder": "Select Children",
                  "toggleTag": "<button type=\"button\"></button>",
                  "toggleClasses": "hs-select-disabled:pointer-events-none hs-select-disabled:opacity-50 relative py-3 px-4 pe-9 flex text-nowrap w-full cursor-pointer bg-white border border-gray-200 rounded-lg text-start text-sm focus:border-blue-500 focus:ring-blue-500 before:absolute before:inset-0 before:z-[1] focus:outline-blue-500 dark:bg-slate-900 dark:border-gray-700 dark:text-gray-400 dark:focus:outline-none dark:focus:ring-1 dark:focus:ring-gray-600",
                  "dropdownClasses": "mt-2 z-50 w-full max-h-[300px] p-1 space-y-0.5 bg-white border border-gray-200 rounded-lg overflow-hidden overflow-y-auto dark:bg-slate-900 dark:border-gray-700",
                  "optionClasses": "py-2 px-4 w-full text-sm text-gray-800 cursor-pointer hover:bg-gray-100 rounded-lg focus:outline-none focus:bg-gray-100 dark:bg-slate-900 dark:hover:bg-slate-800 dark:text-gray-200 dark:focus:bg-slate-800",
                  "optionTemplate": "<div class=\"flex justify-between items-center w-full\"><span data-title></span><span class=\"hidden hs-selected:block\"><svg class=\"flex-shrink-0 w-3.5 h-3.5 text-blue-600 dark:text-blue-500\" xmlns=\"http:.w3.org/2000/svg\" width=\"24\" height=\"24\" viewBox=\"0 0 24 24\" fill=\"none\" stroke=\"currentColor\" stroke-width=\"2\" stroke-linecap=\"round\" stroke-linejoin=\"round\"><polyline points=\"20 6 9 17 4 12\"/></svg></span></div>"
                  }' class="hidden">
                  <option v-for="child in availableChildren" :key="child.name" :value="child.name">
                    {{ child.student_name }}
                  </option>
                </select>
              </div>
            </div>

  
            <!-- Buttons -->
            <div class="flex justify-between mt-8">
              <button type="button" @click="resetChanges" class="bg-white text-blue-600 py-2.5 px-1 w-[135px] text-[14px] text-start rounded-lg hover:text-gray-600 cursor-pointer">Reset changes</button>
              <button type="submit" :disabled="isLoading || isNumberInvalid || isEmailInvalid" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer bg-black text-white text-[14px] hover:bg-opacity-80" style="border: 1px solid #cbd5e1;"
              :class="{
                'bg-gray-300 text-gray-500': isLoading || isNumberInvalid || isEmailInvalid ||isValidationInProgress,
              }">
                <span v-if="isLoading">Saving...</span>
                <span v-else>Save</span>
              </button>
            </div>

            <div v-if="isLoading" class="text-green-500 text-sm mt-4">Creating new Parent</div>
            <div v-if="errorMessage" class="text-red-500 text-sm mt-4">{{ errorMessage }}</div>

          </form>
        </div>
      </div>
    </div>
  </template>
  
  <script setup>
  import { ref, inject, defineEmits, defineProps, computed, onMounted, nextTick, watch } from 'vue';
  
  const props = defineProps({
    optionsResponse: Object,
  });
  
  const emit = defineEmits(['close', 'upload-success']);
  const $call = inject('$call');

  const isLoading = ref(false);
  const errorMessage = ref(null);  // To store error messages


  const roleSelectKey = ref(0);
  const childrenSelectKey = ref(0);
  
  // Initialize form fields with props data
  const parentName = ref(null);
  const phoneNumber = ref('+91');
  const children = ref(null);
  const email = ref(null)
  
  
  const availableRoles = ref([]);
  const selectedRoles = ref(['Parent']);

  const selectedChildren = ref([]);
  const availableChildren = ref(props.optionsResponse.students || []); // `allChildren` is the list received as a prop

  const isEmailInvalid = ref(false); 
  const isNumberInvalid = ref(false); 

  const isValidationInProgress = ref(false);



// Fetch all roles and initialize Preline select on mount
onMounted(async () => {
  // Fetch roles from the API
  try {
    const response = await $call('curriculum_planner.curriculum_planner.common.educator.api.get_list_of_all_the_roles');
    availableRoles.value = response.roles || []; // Assuming roles come in a 'roles' field

    setTimeout(() => {
      window.HSStaticMethods.autoInit(); // Initialize custom selects

      // For role select dropdown
      const roleSelectElement = window.HSSelect.getInstance('#roleSelect');
      if (roleSelectElement) {
        roleSelectElement.on('change', (value) => {
          selectedRoles.value = value ? [...value] : [];
        });
        console.log("selected value changed for Roles",selectedRoles.value)
      } else {
        console.error('RoleSelect instance not found');
      }

      // For Children dropdown
      const childrenSelectElement = window.HSSelect.getInstance('#childrenSelect');
      if (childrenSelectElement) {
        childrenSelectElement.on('change', (value) => {
          selectedChildren.value = value ? [...value] : [];
        });
        console.log("selected value changed for Children",selectedChildren.value)
      }else {
        console.error('childrenSelect instance not found');
      }


    }, 100);
  } catch (error) {
    console.error('Error fetching roles:', error);
  }
});


  // Custom debounce function
  function debounce(func, delay) {
    let timeout;
    return (...args) => {
      clearTimeout(timeout);
      isValidationInProgress.value = true; // Validation started
      timeout = setTimeout(() => {
        func(...args);
        isValidationInProgress.value = false; // Validation completed
      }, delay);
    };
  }

  // Debounced email validation function
  const validateEmail = debounce(() => {
      const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      isEmailInvalid.value = email.value ? !emailPattern.test(email.value) : false;
    }, 1200); // Delay of 300ms

    // Watch the email field and validate with debounce
    watch(email, () => {
      validateEmail();
  });

   // Debounced phone number validation function
   const validatePhoneNumber = debounce(() => {
    const phonePattern = /^\+[0-9]{1,3}[0-9]{10}$/;
    isNumberInvalid.value = phoneNumber.value
      ? !phonePattern.test(phoneNumber.value)
      : false; // True if invalid, false if valid or empty
  }, 1200); 

  // Watch the phoneNumber field and validate with debounce
  watch(phoneNumber, () => {
    validatePhoneNumber();
  });

  const createNewParent = async () => {
    isLoading.value = true;
    errorMessage.value = null; 
    try {
      const response = await $call('curriculum_planner.curriculum_planner.common.parents.api.create_new_parent', {
        parent_name: parentName.value,
        new_roles: selectedRoles.value,
        phone_number: phoneNumber.value,
        user_email: email.value,
        children: selectedChildren.value,
      });
  
      if (response.status === 'success') {
        emit('upload-success');
        emit('close');
      } else {
        errorMessage.value = response.message || 'An error occurred while creating the parent.';
      }
    } catch (error) {
      if (error.message.includes('Duplicate entry')) {
        errorMessage.value = 'A user with this email already exists. Please use a different email.';
      } else {
        errorMessage.value = 'An error occurred while creating the parent.';
      }
      console.error('Error creating parent:', error);
    }
   finally {
    isLoading.value = false;
   }
  };

  const reinitializePrelineSelect = async () => {
    console.log("Reinitializing Preline selects...");

    // Reinitialize Role select
    const roleSelect = window.HSSelect.getInstance(`#roleSelect`);
    if (roleSelect) {
      roleSelect.destroy();
    }
    roleSelectKey.value++;

    // Reinitialize Children select
    const childrenSelect = window.HSSelect.getInstance(`#childrenSelect`);
    if (childrenSelect) {
      childrenSelect.destroy();
    }
    childrenSelectKey.value++;

    await nextTick();
    window.HSStaticMethods.autoInit();
  }; 
  
// Reset the form to original values
const resetChanges = () => {
  parentName.value = ''
  phoneNumber.value = ''
  selectedRoles.value = ['Parent']
  selectedChildren.value = []
  email.value = '';
  isNumberInvalid.value = false;
  isEmailInvalid.value= false;
  reinitializePrelineSelect();
};
  
  
  // Close component
  const closeComponent = () => {
    emit('close');
  };
  </script>
  
  <style scoped>
  /* Styling for the modal */
  .bg-conic-gradient {
    background: conic-gradient(from 90deg at 50% 50%, #FFCE31 0deg, rgba(255, 206, 49, 0.2) 294deg, rgba(255, 206, 49, 0) 360deg);
  }
  
  .spinner-mask {
    --spinner-thickness: 3px;
    mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
    -webkit-mask: radial-gradient(farthest-side, transparent calc(100% - var(--spinner-thickness)), #000 calc(100% - var(--spinner-thickness)));
  }
  </style>
  