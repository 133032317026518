<template>
  <slot name="trigger" />

  <div :id="`hs-${props.modalName}`" class="hs-overlay hs-overlay-open:opacity-100 hs-overlay-open:duration-500 
    hidden size-full fixed top-0 start-0 z-[80] opacity-0 overflow-x-hidden transition-all overflow-y-auto 
    pointer-events-none flex flex-col justify-center items-center">
    <div class="hs-overlay-open:opacity-100 hs-overlay-open:duration-500 opacity-0 transition-all 
       sm:w-full w-[95%] m-3 min-h-2/3 max-h-5/6 lg:w-1/2">
      <div class="flex flex-col bg-white border shadow-sm rounded-xl pointer-events-auto dark:bg-gray-800 
        dark:border-gray-700 dark:shadow-slate-700/[.7] h-full">
        <div class="space-y-2">
          <div class="flex justify-between items-center pt-4 px-4 dark:border-gray-700">
            <h3 class="font-semibold text-[#6B7280] text-lg md:text-xl">
              Weekly Child Observation
            </h3>
            <button type="button" class="flex justify-center items-center size-7 text-sm font-semibold
              rounded-full border border-transparent text-gray-800 hover:bg-gray-100 disabled:opacity-50
              disabled:pointer-events-none dark:text-white dark:hover:bg-gray-700 dark:focus:outline-none
              dark:focus:ring-1 dark:focus:ring-gray-600" :data-hs-overlay="`#hs-${props.modalName}`">
              <span class="sr-only">Close</span>
              <svg class="flex-shrink-0 size-4" xmlns="http://www.w3.org/2000/svg" width="24" height="24"
                viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                stroke-linejoin="round"><path d="M18 6 6 18"/><path d="m6 6 12 12"/></svg>
            </button>
          </div>
          <DateDisplay :date="selectedDate" />
        </div>
        <form @submit.prevent="handleSubmit" name="unstruct-observ-form" class="flex-auto flex flex-col font-normal"
          ref="formRef">
          <div :class="cn(`max-h-[60vh]`, pageNum !== 1 && 'overflow-y-auto')">
            <WeeklyChildPage1 v-if="studentsList.data && props.userData" :pageNum="pageNum" :userData="props.userData" 
              :studentsList="studentsList.data" @date:selected="updateDate" />
            <!-- <div v-for="section in sections">
              <WeeklyChildQuestion v-for="(question, index) in section.questions" :pageNum="pageNum"
                :componentPageNum="1 + question.number " :questionNumber="index + 1" :question="question.question"
                :options="question.options" :inputName="question.input" :section="section.section"
                :sectionNumber="index" :textInputName="question.text_input || ''" />
            </div> -->
            <div v-for="(section, sectionIndex) in formStructure">
              <template v-for="(question, questionIndex) in section.questions" :key="question.id">
                <MultipleChoiceQuestion v-if="question.type === 'multipleChoice' || question.type === 'multipleChoiceWithOther'" 
                  :pageNum="pageNum" :componentPageNum="1 + question.number" :question="question"
                  :questionNumber="questionIndex + 1" :section="section.title" :sectionNumber="sectionIndex + 1" />
                <TextQuestion v-if="question.type === 'text'" :pageNum="pageNum" 
                  :componentPageNum="1 + question.number" :question="question" :questionNumber="questionIndex + 1" 
                  :section="section.title" :sectionNumber="sectionIndex + 1" />
                <CompetencyPicker v-if="question.type === 'competencyPicker'" :pageNum="pageNum" 
                  :componentPageNum="1 + question.number" :question="question" :competencyList="competencyList"
                  :questionNumber="questionIndex + 1"
                  :section="section.title" :sectionNumber="sectionIndex + 1" 
                  @change:comps="(newValue) => updateCompetencies(newValue, question.id)" />
                <TextWithMultipleChoice  v-if="question.type === 'textWithMultipleChoice'" :pageNum="pageNum" 
                  :componentPageNum="1 + question.number" :question="question" :questionNumber="questionIndex + 1" 
                  :section="section.title" :sectionNumber="sectionIndex + 1" />
              </template>
            </div>
          </div>
          <div :class="cn(`text-sm font-semibold text-red-500 p-3 text-center`, 
            hideInputWarning && 'hidden')">
            Please answer all the required questions!
          </div>
          <!-- <div class="flex flex-col md:flex-row gap-4 md:items-center md:justify-between"> -->
          <div class="flex flex-col gap-4">
            <div class="flex gap-2 items-center pr-8">
              <div class="px-8 md:px-4 flex gap-1 items-center flex-wrap">
                <div v-for="n in totalPageNum" :class="cn(`h-2 w-3 bg-[#F3F4F6]`, n <= pageNum && 'bg-[#FFD700]')">
                  <!-- <div :class="cn(`h-full w-full`, n <= pageNum && 'bg-[#FFD700]')"></div> -->
                </div>
              </div>
              <div class="text-sm font-semibold text-gray-600">
                {{ pageNum }}/{{ totalPageNum }}
              </div>
            </div>
            <div class="flex justify-center md:justify-end items-center gap-x-4 py-3 px-4 dark:border-gray-700">
              <button type="button" class="py-2.5 px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-medium
                rounded-md border border-gray-200 bg-white text-gray-800 shadow-sm hover:bg-gray-50
                disabled:opacity-50 disabled:pointer-events-none dark:bg-slate-900 dark:border-gray-700
                dark:text-white dark:hover:bg-gray-800 dark:focus:outline-none dark:focus:ring-1
                dark:focus:ring-gray-600" @click="goToPrevPage" :disabled="pageNum === 1">
                Back
              </button>
              <button type="button" :class="cn(`py-2.5 px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-md border border-transparent text-white text-white
                disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                disabled:bg-[#4B5563] disabled:text-[#D1D5DB] bg-black hover:bg-[#1F2937]
                dark:focus:ring-gray-600`, pageNum === totalPageNum && 'hidden')" @click="goToNextPage" :disabled="pageNum === totalPageNum">
                Next
              </button>
              <button type="submit" :class="cn(`py-2.5 px-8 md:px-14 inline-flex items-center gap-x-2 text-sm font-semibold
                rounded-md border border-transparent text-white text-white
                disabled:pointer-events-none dark:focus:outline-none dark:focus:ring-1 
                disabled:bg-[#4B5563] disabled:text-[#D1D5DB] bg-black hover:bg-[#1F2937]
                dark:focus:ring-gray-600`, pageNum !== totalPageNum && 'hidden')" :disabled="(pageNum !== totalPageNum) || isSubmitting">
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, onMounted, inject } from 'vue';
import { cn } from '@/components/ui/utils.js'
import { createResource, createListResource } from 'frappe-ui'
import { HSOverlay } from 'preline/preline';
import DateDisplay from '../DateDisplay.vue';
import WeeklyChildPage1 from './WeeklyChildPage1.vue';
import { formStructure } from './weeklyChildQuestions';
import WeeklyChildQuestion from './WeeklyChildQuestion.vue';
import MultipleChoiceQuestion from '../FormPages/MultipleChoiceQuestion.vue';
import TextQuestion from '../FormPages/TextQuestion.vue';
import CompetencyPicker from '../FormPages/CompetencyPicker.vue';
import TextWithMultipleChoice from '../FormPages/TextWithMultipleChoice.vue';

const props = defineProps([
  'modalName', 'userData', 'reloadObservations',
  'confirmModalName'
])
const competencyList = inject('observationCompetencies')

const pageNum = ref(1)
const totalPageNum = 19
const selectedDate = ref(null)

const formRef = ref(null)
const hideInputWarning = ref(true)

const setHideWarningTime = (time) => {
  setTimeout(() => {
    if (hideInputWarning.value === false)
      hideInputWarning.value = true
  }, time)
}

onMounted(() => {
  // console.log("preline mounted and initiated in App.vue");
  setTimeout(() => {
    window.HSStaticMethods.autoInit();
  }, 100)
});

const studentsList = createListResource({
  doctype: 'Student',
  fields: ['name', 'student_name', 'cohort'],
  orderBy: 'student_name asc',
  filters: {
    is_enrolled: 1,
  },
  start: 0,
  pageLength: 30,
  auto: true
})

const goToPrevPage = () => {
  if (pageNum.value === 1) return
  pageNum.value = pageNum.value - 1
}

const goToNextPage = () => {
  const formData = new FormData(formRef.value)
  if (pageNum.value === 1) { 
    const eduId = formData.get('educator_id')
    const date = formData.get('date')
    const student = formData.get('student_name')
    if (!(eduId && date && student)) {
      hideInputWarning.value = false
      return
    } else {
      hideInputWarning.value = true
    }
  }
  hideInputWarning.value = true
  pageNum.value = pageNum.value + 1
}

const updateDate = (date) => {
  console.log(date)
  selectedDate.value = date
}

const engagingCompetencies = ref([])
const lessInterestingCompetencies = ref([])
const updateCompetencies = (newComps, type) => {
  console.log({newComps, type})
  if (type === 'engaging_activities') engagingCompetencies.value = newComps
  else if (type === 'less_interesting_activities') lessInterestingCompetencies.value = newComps
}

const observationSaveResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.doctype.weekly_child_observations.api.create_new_observation'
})

const isSubmitting = ref(false)

const handleSubmit = async (e) => {
  isSubmitting.value = true
  const formData = new FormData(e.target)

  const formDataObj = {};
  formData.forEach((value, key) => {
      // Reflect.has in favor of: object.hasOwnProperty(key)
      if(!Reflect.has(formDataObj, key)){
          formDataObj[key] = value;
          return;
      }
      if(!Array.isArray(formDataObj[key])){
          formDataObj[key] = [formDataObj[key]];    
      }
      formDataObj[key].push(value);
  });
  // if (!('creative_solving_desc' in formDataObj))
  //   formDataObj.creative_solving_desc = ''
  formDataObj.engaging_activities = engagingCompetencies.value;
  formDataObj.less_interesting_activities = lessInterestingCompetencies.value;
  console.log({formDataObj})
  // isSubmitting.value = false
  // return;
  try {
    HSOverlay.close(`#hs-${props.modalName}`)
    await observationSaveResource.submit({
      observation_data: formDataObj
    })
    if (observationSaveResource.data.status === 'success') {
      pageNum.value = 1
      // HSOverlay.open('#hs-form-confirm-modal-1')
      console.log("Successfully saved observation");
      HSOverlay.open(`#hs-form-confirm-modal-${props.confirmModalName}`)
      await new Promise(resolve => setTimeout(resolve, 250));
      if (props.reloadObservations) props.reloadObservations()
      if (props.reloadFn) props.reloadFn()
    } else {
      console.error("Failed to save observation")
    }
  } catch (error) {
    console.error("Failed to make observation save request")
    console.error(error)
  }
  isSubmitting.value = false
}
</script>