<template>
  <div class="flex flex-col gap-4">
    <div v-if="studentObj">{{ studentObj.student_name }}</div>
    <SummaryControls :pickedModel="pickedModel" :pickNewModel="pickNewModel" :prompt="prompt" 
      :usePrompt="changePrompt" :reloadObservations="reloadObservations" 
      :checkForExistingReports="checkForExistingReports"
      @change:start="(newStart) => startDate = newStart" @change:end="(newEnd) => endDate = newEnd"
      @change:loadAll="(newAll) => shouldLoadAllObservations = newAll"
      :openAllModal="openGenerateAllModal" @change:defaultPrompt="defaultPromptFlag = !defaultPromptFlag" />
    <GenerateAllDisplay :generateResource="generateForAllResource" :isGeneratingAll="isGeneratingAll"
      :prompt="prompt" :defaultFlag="defaultPromptFlag" />
    <!-- <GenerateAllDisplay v-if="isGeneratingAll" /> -->
    <SummaryDisplay v-if="prompt.name || observationResource.data?.competency_scores" :prompt="prompt" 
      :summary="summaryText" :isGenerating="isGenerating" 
      :competencyScores="observationResource.data.competency_scores" />
    <ObservationList v-if="observationResource.data?.status=='success'" 
      :dailyObservList="observationResource.data.observations" 
      :activityObservList=observationResource.data.activity_observations
      :activityMentionList="observationResource.data.activity_mentions"
      :assessmentReport="observationResource.data.assessment_reports[0] || null" />
  </div>
  <ReportModal modalName="checkReportsModal" :reportListResource="reportListResource" :prompt="prompt"
    :startDate="startDate" :endDate="endDate" :shouldLoadAll="shouldLoadAllObservations" :generateReport="generateReport" 
    :studentId="props.student" :model="pickedModel" />
  <GenerateAllModal modalName="generateAllModal" :reportListResource="reportListResource" :prompt="prompt"
    :startDate="startDate" :endDate="endDate" :shouldLoadAll="shouldLoadAllObservations" :generateReport="generateReport" 
    :studentId="props.student" :model="pickedModel" :generateReportsForAll="showConfirmAllDialog" />
  <GenerateAllDialog modalName="generateAllDialog" :generateAllFn="generateReportsForAll" />
</template>

<script setup>
import { computed, inject, ref, watch } from 'vue';
import { createResource } from 'frappe-ui';
import { useRoute } from 'vue-router';
import ObservationList from './ObservationList.vue';
import SummaryDisplay from './SummaryDisplay.vue';
import SummaryControls from './SummaryControls.vue';
import ReportModal from './ReportModal.vue';
import GenerateAllModal from './GenerateAllModal.vue';
import GenerateAllDialog from './GenerateAllDialog.vue';
import GenerateAllDisplay from './GenerateAllDisplay.vue'
import { getDateFromPreviousMonth, getTodayDateJSFormat } from '../../../../lib/utils';
import { HSOverlay } from 'preline/preline';

const props = defineProps(['cohortStudents', 'student'])

const route = useRoute()
const studentId = ref(route.query.student || '')

const userDetails = inject('userDetails')
const studentObj = computed(() => {
  if (!props.student) return {}
  if (!userDetails.value?.school_info) return {}
  const student = userDetails.value.school_info.students.find((obj) => obj.name === props.student)
  return student || {}
})

const today = getTodayDateJSFormat()
const endDate = ref(today)
const startDate = ref(getDateFromPreviousMonth(today))
const shouldLoadAllObservations = ref(true)

const defaultPromptFlag = ref(false)

const observationResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_individual_observations',
})

if (studentId) {
  observationResource.submit({ 
    student: studentId.value, 
    start_date: startDate.value,
    end_date: endDate.value,
    load_all: shouldLoadAllObservations.value,
  })
  console.log({observationResource})
}

const reloadObservations = () => {
  const params = { 
    student: route.query.student,
    start_date: startDate.value,
    end_date: endDate.value,
    load_all: shouldLoadAllObservations.value,
  }
  observationResource.submit(params)
  console.log({ params })
  console.log(observationResource)
}
watch(() => route.query.student, () => {
  reloadObservations()
})

const pickedModel = ref('claude-3-5-sonnet')
const prompt = ref({ name: '', prompt_name: '', prompt: '' })

const pickNewModel = (newModel) => {
  pickedModel.value = newModel
}

const changePrompt = (newPrompt) => {
  prompt.value = newPrompt
}

watch(() => observationResource.data, (newData) => {
  if (newData.default_prompt)
    prompt.value = newData.default_prompt
})

const summaryText = ref('')
const isGenerating = ref(false)

const generateReportResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_individual_observ_report',
})

const generateForAllResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.generate_report_for_all',
})

const reportListResource = createResource({
 url: '/api/method/curriculum_planner.curriculum_planner.common.observations.summary.api.get_saved_reports',
})

const checkForExistingReports = async () => {
  try {
    await reportListResource.submit({ student: studentId.value })
    if (reportListResource.data.status === 'success') {
      console.log(reportListResource)
      HSOverlay.open('#hs-checkReportsModal')
    } else {
      console.error('Server call failed')
      console.log(reportListResource)
    }
  } catch (error) {
    console.error(`Failed to make report list request: ${error}`)
  }
}

const openGenerateAllModal = () => {
  HSOverlay.open('#hs-generateAllModal')
}

const assessmentTypeAll = ref('raw')
const selectedDataAll = ref(['individual', 'activity', 'mention'])

const showConfirmAllDialog = (assessment_type, data_options) => {
  assessmentTypeAll.value = assessment_type
  selectedDataAll.value = data_options
  HSOverlay.open('#hs-generateAllDialog')
}

const isGeneratingAll = ref(false)

const generateReportsForAll = async () => {
  HSOverlay.close('#hs-generateAllDialog')
  isGeneratingAll.value = true;
  try {
    await generateForAllResource.submit({
      assessment_type: assessmentTypeAll.value,
      data_options: selectedDataAll.value,
    })
  } catch (error) {
    console.error("Error making generate request: ")
    console.error(error)
  }

}

const generateReport = async (assessmentType, dataOptions) => {
  isGenerating.value = true
  try {
    await generateReportResource.submit({
      student: studentId.value,
      start_date: shouldLoadAllObservations.value ? "" : startDate.value,
      end_date: shouldLoadAllObservations.value ? "" : endDate.value,
      prompt: prompt.value.prompt,
      prompt_id: prompt.value.name,
      model: pickedModel.value,
      assessment_type: assessmentType,
      data_options: dataOptions,
    })
    if (generateReportResource.data.status === 'success') {
      summaryText.value = generateReportResource.data.report
      console.log(`Summary generated in ${generateReportResource.data.api_time}s`)
      console.log('Tokens: ', generateReportResource.data.tokens)
    } else {
      console.log(`Failed to generate report: ${generateReportResource.data.error}`)
    }
  } catch (error) {
    console.error(`Failed to make generate request: ${error}`)
  }
  isGenerating.value = false
}
</script>