<template>
  <v-chart class="chart" :option="option" autoresize />
</template>

<script setup>
import { computed } from 'vue'
import { use } from 'echarts/core'
import { BarChart } from 'echarts/charts'
import { GridComponent, TooltipComponent } from 'echarts/components'
import { CanvasRenderer } from 'echarts/renderers'
import VChart from 'vue-echarts'

const props = defineProps({
  categories: {
    type: Array
  },
  values: {
    type: Array
  },
  color: {
    type: Array,
    default: () => ['#FDD835']
  },
  inverse: {
    type: Boolean,
    default: false,
  },
  showNames: {
    type: Boolean,
    default: true,
  },
  showYTick: {
    type: Boolean,
    default: true,
  },
  barCategoryGap: {
    type: String,
    default: '20%',
  },
  yAxisLabelSize: {
    type: String,
    default: 12,
  },
})

console.log("Activity graph color?", props.color)

use([GridComponent, BarChart, CanvasRenderer, TooltipComponent])

const option = computed(() => ({
  tooltip: {
    trigger: 'axis',
    axisPointer: {
      type: 'shadow'
    }
  },
  xAxis: {
    type: 'value'
  },
  yAxis: {
    type: 'category',
    //data: ['Last Month', 'Current Month']
    inverse: props.inverse,
    data: props.categories,
    axisLabel: {
      show: props.showNames,
      fontSize: props.yAxisLabelSize,
    },
    axisTick: {
      show: props.showYTick,
    }
  },
  series: [
    {
      //data: [120, 200],
      data: props.values.map((value, index) => ({
        value: value,
        itemStyle: {
          color: props.color[index % props.color.length]
        }
      })),
      type: 'bar',
      barCategoryGap: props.barCategoryGap,
      barGap: '0%',
      itemStyle: {
        normal: {
            // Shadow properties
            shadowColor: 'rgba(0, 0, 0, 0.2)',  // Shadow color
            shadowBlur: 10,                    // Blur radius
            shadowOffsetX: 0.01,                  // Horizontal offset
            shadowOffsetY: 0.01                   // Vertical offset
        }
      }
    }
  ],
  grid: {
    containLabel: true,
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
}))
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
}
</style>