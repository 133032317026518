<template>
  <div class="fixed top-0 left-0 w-full h-full bg-black bg-opacity-50 flex items-center justify-center z-50 overflow-auto" @click.self="closeComponent">
    <div class="bg-white rounded-xl shadow-md w-11/12 md:w-3/5 lg:w-2/5 h-auto max-h-[97%] max-w-[450px] md:max-w-[750px] min-h-[50%] overflow-auto px-6 py-3">
      
      <!-- Close Button -->
      <div class="sticky top-6 mr-5 flex justify-end z-10">
        <button @click="closeComponent" class="border-black">
          <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M1 1.01562L11 11.0156M1 11.0156L11 1.01562" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
          </svg>
        </button>
      </div>

      <h3 class="font-semibold text-[#6B7280] text-lg md:text-xl">
              Message Details
      </h3>

      
      <!-- Message Content -->
      <div class="flex flex-col gap-4">
        <!-- Message Content -->
        <div class="flex flex-col gap-1 mt-4">
          <div class="font-medium">Message Content</div>
          <div class="text-gray-600">{{ message?.message_content }}</div>
        </div>
        
        <!-- Sent on Date -->
        <div class="flex flex-col gap-1">
          <div class="font-medium">Sent on Date</div>
          <div class="text-gray-500">{{ moment(message?.creation_time).format('DD.MM.YY') }}</div>
        </div>
        
        <!-- Sent To -->
        <div class="flex flex-col gap-1">
          <div class="font-medium">Sent To</div>
          <div class="text-gray-600">{{ message?.sent_to_roles.join(',') }}</div> <!-- Placeholder, update as needed -->
        </div>
        
        <!-- Sent By -->
        <div class="flex-col gap-1">
          <div class="font-medium">Sent By</div>
          <div class="text-gray-600">Admin</div> <!-- Placeholder, update as needed -->
        </div>
        
        <!-- Type -->
        <div class="flex-col gap-1">
          <div class="font-medium">Type</div>
          <div class="text-gray-600">{{ message?.message_type }}</div>
        </div>
      </div>  


      <!-- Navigation Buttons -->
      <div class="flex justify-end gap-5 mt-4">
        <button @click="goToPrevious" :disabled="messageIndex < 0" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer bg-white text-black text-[14px] hover:bg-opacity-80" style="border: 1px solid #cbd5e1;">
          Previous
        </button>
        <button @click="goToNext" :disabled="messageIndex > messageList.length - 1" class="py-2.5 px-2 w-[105px] rounded-lg cursor-pointer bg-black text-white text-[14px] hover:bg-opacity-50 hover:bg-gray-500" style="border: 1px solid #cbd5e1;">
          Next
        </button>

      </div>
    </div>
  </div>
</template>

<script setup>
import { ref, watch, onMounted, computed, inject } from 'vue';
import moment from 'moment';

// Inject the $call method
const $call = inject('$call');

const props = defineProps({
  message: Object,
  messageList: Array,
  messageIndex: Number
});

onMounted(()=>{
  console.log("These are props",props)
  markMessageAsViewed()
})

const emit = defineEmits(['update:message', 'update:messageIndex']);


const markMessageAsViewed = async () => {
    try {
      const response = await $call('curriculum_planner.curriculum_planner.doctype.admin_messages.api.mark_user_as_viewed_once', {
        message_id: props.message.name
      });
   
    } catch (error) {
      console.error('Error marking message as viewed:', error);
    
    }
  };




const goToNext = () => {
  console.log("messageIndex:", props.messageIndex, "messageList length:", props.messageList.length);

  if (props.messageIndex < props.messageList.length - 1) {
    // Move to the next message within the current page
    emit('update:messageIndex', props.messageIndex + 1);
    emit('update:message', props.messageList[props.messageIndex + 1]);
  } else {
    // At the end of the current page, request to load the next page
    console.log("emitting next page");
    emit('nextPage');
  }
};


const goToPrevious = () => {
  if (props.messageIndex > 0) {
    // Move to the previous message within the current page
    emit('update:messageIndex', props.messageIndex - 1);
    emit('update:message', props.messageList[props.messageIndex - 1]);
  } else {
    // At the start of the current page, request to load the previous page
    emit('previousPage'); // Emit event to parent to load the previous page
  }
};


const closeComponent = () => {
  emit('update:message', null); // Close modal by setting message to null
};
</script>
